import { ItemRepeat, StaticFieldGroup } from "@banklampung/components";
import { cleanValue, notEmpty, GlobalHelper } from "@banklampung/helper";
import React from "react";

const StaticBody = (props) => {
  const { heading, values } = props;
  let last = {};

  if (values.length % 2 === 1) {
    last = values[values.length - 1];
    values.splice(-1, 1);
  }

  return (
    <>
      {heading && <h5 className="mb-2 mt-3">{heading}</h5>}
      <ItemRepeat
        values={[GlobalHelper.leftRightCol(values, 2)]}
        card={false}
      />
      {notEmpty(last) && (
        <StaticFieldGroup
          label={last.label}
          value={cleanValue(last.value)}
          help={last.help}
        />
      )}
    </>
  );
};
export default StaticBody;
