const DISBURSEMENT_ENDPOINT                 = "ayuna/disbursement-governments";
const TRANSACTION_ENDPOINT                  = "ayuna/transactions";
const MENU_ENDPOINT                         = "ayuna/menus";
const PRODUCT_ENDPOINT                      = "ayuna/products";

const AYUNA_ACCOUNT_BULK_ENDPOINT          = "ayuna/v1/accounts/bulk";
const AYUNA_ACCOUNT_ENDPOINT               = "ayuna/v1/accounts";
const AYUNA_CORPORATE_ENDPOINT             = "ayuna/v1/corporates";
const AYUNA_CORPORATE_MENU_ENDPOINT        = "ayuna/v1/corporate-menus";
const AYUNA_MENU_ENDPOINT                  = "ayuna/v1/menus";
const AYUNA_PROFILE_ENDPOINT               = "ayuna/v1/profiles";
const AYUNA_VERIFY_ENDPOINT                = "ayuna/v1/authorize-transactions";
const AYUNA_TASK_ENDPOINT                  = "ayuna/v1/tasks";
const AYUNA_TASK_FLOW_ENDPOINT             = "ayuna/v1/task-flows";
const AYUNA_TEMPLATES_MENU_ENDPOINT        = "ayuna/v1/templates";
const AYUNA_BENEFICARY_ENDPOINT            = "ayuna/v1/government-beneficiaries";
const AYUNA_BENEFICARY_ATTACHMENT_ENDPOINT = "ayuna/v1/attachments";
const AYUNA_MASTER_DEDUCTION_ENDPOINT      = "ayuna/v1/master/deductions";

const AYUNA_URL_TEMPLATE_ENDPOINT          = "http://192.168.188.38/ayuna/template/Template%20Rek%20Penerima%20L-Finns.zip";
// const AYUNA_URL_TEMPLATE_ENDPOINT          = "https://assets.banklampung.co.id/ayuna/template/Template%20Rek%20Penerima%20L-Finns.zip";

export default {
  DISBURSEMENT_ENDPOINT,
  TRANSACTION_ENDPOINT,
  MENU_ENDPOINT,
  PRODUCT_ENDPOINT,

  AYUNA_ACCOUNT_BULK_ENDPOINT,
  AYUNA_ACCOUNT_ENDPOINT,
  AYUNA_CORPORATE_ENDPOINT,
  AYUNA_CORPORATE_MENU_ENDPOINT,
  AYUNA_MENU_ENDPOINT,
  AYUNA_PROFILE_ENDPOINT,
  AYUNA_VERIFY_ENDPOINT,
  AYUNA_TASK_ENDPOINT,
  AYUNA_TASK_FLOW_ENDPOINT,
  AYUNA_TEMPLATES_MENU_ENDPOINT,
  AYUNA_BENEFICARY_ENDPOINT,
  AYUNA_BENEFICARY_ATTACHMENT_ENDPOINT,
  AYUNA_URL_TEMPLATE_ENDPOINT,
  AYUNA_MASTER_DEDUCTION_ENDPOINT
};
