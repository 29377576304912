import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  FooterSubmitForm,
  LoadLazy,
  Card,
  LoaderButton,
} from "@banklampung/components";

const BasicForm = ({
  children,
  onSubmit,
  formName,
  formLabel,
  formSubLabel,
  formLabelOverride,
  body,
  buttonSubmit,
  slotButton,
  buttonAlign,
  propsCard,
  outsideEnd,
}) => {
  const refBtnSubmit = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!buttonSubmit.disabled) {
      refBtnSubmit.current.click();
    }
  };

  return (
    <Card
      headerSlot={
        <>
          {formLabelOverride ? (
            <strong>{formLabelOverride}</strong>
          ) : (
            <strong>
              Form {formLabel} <small>{formSubLabel}</small>
            </strong>
          )}
        </>
      }
      footerSlot={
        <FooterSubmitForm
          button={
            slotButton ? (
              slotButton
            ) : (
              <LoaderButton {...buttonSubmit} onClick={handleSubmit} />
            )
          }
          align={buttonAlign}
        />
      }
      {...propsCard}
    >
      <LoadLazy>
        <form onSubmit={onSubmit} name={formName} id={formName}>
          {body || children}

          <button
            type="submit"
            ref={refBtnSubmit}
            style={{
              display: "none",
            }}
          ></button>
        </form>
        {outsideEnd}
        <div className="required">* Mandatory</div>
      </LoadLazy>
    </Card>
  );
};

BasicForm.propTypes = {
  onSubmit: PropTypes.any.isRequired,
  formName: PropTypes.string.isRequired,
  body: PropTypes.any,
  buttonSubmit: PropTypes.any.isRequired,
};

BasicForm.defaultProps = {
  propsCard: {},
  buttonSubmit: {
    disabled: false,
  },
};

export default BasicForm;
