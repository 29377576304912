import React from "react";
import { SpinChase } from "@banklampung/components";

const ContentLoading = (props) => {
  const { title, message } = props;
  return (
    <div className="d-flex flex-column justify-content-center align-items-center p-5">
      <SpinChase />
      <div className="mt-5 text-center">
        {title && <h2 className="font-weight-bold font-dark">{title}</h2>}
        <span>{message || "Sedang memuat..."}</span>
      </div>
    </div>
  );
};

export default ContentLoading;
