const ACCOUNT_ENDPOINT        = "ficus/accounts";
const TELLER_ENDPOINT         = "ficus/tellers";
const CUSTOMER_ENDPOINT       = "ficus/customers/:cif";
const CUSTOMER_CIF_ENDPOINT   = "ficus/customers/find-by-cif";
const OPEN_ACCOUNT            = "ficus/open-accounts";
const PAYMENT_MPN_ENDPOINT    = "ficus/payment-mpn";

export default {
  ACCOUNT_ENDPOINT,
  TELLER_ENDPOINT,
  CUSTOMER_ENDPOINT,
  CUSTOMER_CIF_ENDPOINT,
  OPEN_ACCOUNT,
  PAYMENT_MPN_ENDPOINT
};
