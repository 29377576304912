import _ from "@banklampung/libs/lodash";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import camelcaseKeys from "camelcase-keys";
import { timeoutInMilis } from "@banklampung/config";

const isErrorHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("errorHandlerEnabled") &&
    !config.errorHandlerEnabled
    ? false
    : true;
};

const isDialogHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("dialogHandlerEnabled") &&
    !config.dialogHandlerEnabled
    ? false
    : true;
};

const getTitleError = (src, message) => {
  if (!_.isEmpty(message)) {
    if (typeof message === "object") {
      src = message.indonesian;
    } else {
      src = message;
    }
  }

  return src;
};

const customHandler = (value) => {
  const {error, errorCode, handleErorCodeExact} = value;
  const { data } = error.response || {};

  if (_.isPlainObject(data)) {
    const responseData = camelcaseKeys(data, { deep: true });

    if(responseData.errorCode == errorCode){
      handleErorCodeExact(responseData);
    }else{
      errorHandler({
        ...error,
        custom: true
      });
    }
  }else{
    errorHandler({
      ...error,
      custom: true
    });
  }
}

const errorHandler = (error) => {
  const axiosErrorCode = error.code;
  const { response, custom=false } = error;
  const { data, statusText } = response || {};

  let title = `${statusText}`,
    priority = false;
  let messages = "";
  let description = "";

  if(statusText == undefined){
    title = "Network Error";
    messages = "Harap periksa Jaringan/Koneksi di perangkat Anda";
  }

  if (_.isPlainObject(data)) {
    const responseData = camelcaseKeys(data, {
      deep: true,
    });

    if (responseData.message) {
      description = getTitleError(description, responseData.message);
    } else {
      description = getTitleError(description, responseData.description);
    }

    if (responseData.errorCode) {
      title = responseData.errorCode;
      messages += `${description}</br>`;
    } else {
      title = description;
    }

    if (responseData.error === "invalid_grant") {
      priority = true;
      title = "Otentikasi Gagal";
      messages += `Periksa kembali username dan password Anda\n\natau hubungi Divisi IT`;
    }

    if (data.error_code === "invalid_grant") {
      title = "Akses Tidak Valid";
    }

    if (!_.isEmpty(responseData.errors)) {
      let errors = camelcaseKeys(responseData.errors);

      for (let key in errors) {
        if (errors.hasOwnProperty(key)) {
          messages += `${key}: ${errors[key]}</br>`;
        }
      }
    }
  } else if (axiosErrorCode === "ECONNABORTED") {
    priority = true;
    title = "Timeout";
    messages += `Melebihi ${timeoutInMilis}ms dari waktu tunggu`;
  }

  if(custom == true){
    Swal.fire({
      icon: "error",
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
      title: title,
      html: messages,
    });

    return error;
  }

  if (isErrorHandlerEnabled(error.config) || priority ) {
    if (isDialogHandlerEnabled(error.config)) {
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
          title: title,
          html: messages,
        });
      }, 80);
    } else {
      toast.error(`[${title}] ${messages}`);
    }
  }

  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};

export { successHandler, errorHandler, customHandler };
