import * as Actions from "../actions";

const initialState = {
  username: "",
  email: "",
  identity: "",
  fullName: "",
  position: "",
  department: {},
  ledger: {},
  authorities: [],
  roles: [],
  additionalInfo: {},
  exist: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      const payload = action.payload;
      const department = payload.department || {};
      const code =
        department.typeCode === "370" ? department.typeCode : department.code;
      const name =
        department.typeCode === "370" ? department.type : department.name;

      return {
        ...initialState,
        ...action.payload,
        exist: true,
        ledger: {
          code: code,
          name: name,
        },
      };
    }

    case Actions.USER_LOGGED_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
