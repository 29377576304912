import {
  BasicForm,
  ColTwo,
  FieldGroup,
  LoadLazy,
} from "@banklampung/components";
import _ from "@banklampung/libs/lodash";
import { history } from "@banklampung/utils";
import React, { useEffect, useMemo } from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

const FilterRoute = ({
  name,
  children,
  defNavType,
  handleChange,
  handleSubmit,
  isLoading,
  propsNav,
}) => {
  const prefixNav = "nav-filter";
  const { routes } = propsNav;
  const _routes = useMemo(() => routes, []);
  useEffect(() => {
    const nav = document.getElementById(`${prefixNav}-${defNavType.value}`);
    if (nav) {
      nav.click();
    }
  }, [defNavType.value]);

  return (
    <ColTwo
      propsRow={{
        className: "flex-column-reverse flex-md-row",
      }}
      propsColA={{ md: 9 }}
      propsColB={{ md: 3 }}
      childA={
        <Router history={history}>
          {_routes.map((route, idx) => (
            <Link
              key={idx}
              to={route.to}
              id={`${prefixNav}-${route.value}`}
            ></Link>
          ))}

          <LoadLazy>
            <Switch>
              {_routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    exact={route.exact}
                    path={route.path}
                    name={route.label}
                    render={(props) => (
                      <route.component
                        {...props}
                        {...route.props}
                        pageTitle={route.label}
                      />
                    )}
                  />
                ) : null;
              })}
            </Switch>
          </LoadLazy>
        </Router>
      }
      childB={
        <BasicForm
          onSubmit={handleSubmit}
          formName="formFilter"
          formLabel={`Filter ${name}`}
          formIcon="filter"
          body={
            <div className="d-flex flex-column">
              <FieldGroup
                id="navType"
                label={name}
                type="input"
                fieldType="select"
                isRequired={true}
                validate={`["required"]`}
                onChange={handleChange}
                options={_routes}
                value={!_.isEmpty(defNavType) && defNavType.value}
                disableDefOpts
              />

              {children}
            </div>
          }
          buttonSubmit={{
            propsIcon: { name: "cil-filter" },
            label: "Filter",
            isLoading: isLoading,
          }}
          buttonAlign="center"
        />
      }
    />
  );
};

FilterRoute.propTypes = {};

export default FilterRoute;
