import React, { useMemo } from "react";
import { SpanError, HasError } from "@banklampung/components";

const textError = ({ method, errorLabel, label, param }) => {
  if (errorLabel) {
    switch (method) {
      case "required": {
        return `Isian ${label} harus di isi`;
      }
      default: {
        return errorLabel;
      }
    }
  } else {
    switch (method) {
      case "required": {
        return `Isian ${label} harus di isi`;
      }
      case "minlen": {
        return `Panjang isian ${label} minimal ${param} karakter`;
      }
      case "maxlen": {
        return `Panjang isian ${label} maksimal ${param} karakter`;
      }
      case "min": {
        return `Minimal nilai isian ${label} harus ${param}`;
      }
      case "max": {
        return `Maksimal nilai isian ${label} harus ${param}`;
      }
      case "len": {
        const [min, max] = JSON.parse(param);
        return `Rentang isian ${label} antara ${min} hingga ${max}`;
      }
      case "email": {
        return `Alamat email/surel tidak valid`;
      }
      case "number": {
        return `Isian ${label} harus bertipe angka`;
      }
      case "integer": {
        return `Isian ${label} harus bertipe bilangan bulat`;
      }
      case "url": {
        return `Isian url tidak valid`;
      }
      default: {
        return `Isian ${label} tidak valid`;
      }
    }
  }
};

const BlockError = props => {
  const { id, form, validate } = props;

  const blockError = useMemo(
    () =>
      validate
        .filter(method => HasError(form, id, method))
        .map(method => {
          return <SpanError key={id} error={textError({ method, ...props })} />;
        }),
    [id, form]
  );

  return <>{blockError}</>;
};

export default BlockError;
