import { ColTwo, StaticCardForm } from "@banklampung/components";
import _ from "@banklampung/libs/lodash";
import { history } from "@banklampung/utils";
import { CListGroup, CListGroupItem, CNav, CNavLink, CNavItem } from "@coreui/react";
import { TextStyle } from "@js-joda/core";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import "./style.scss";

const BasicHorTab = (props) => {
  const { formName, routes, location, match, currentNav, children, headerActions, labelName } = props;

  const getUrl = (tabType) => {
    const params = match.params;
    let path = match.path;

    _.forEach(params, function (value, key) {
      path = path.replaceAll(`:${key}`, key === "tabType" ? tabType : value);
    });

    return path;
  };

  const [activeNav, setActiveNav] = useState(() => {
    const defNav = _.find(
      routes,
      (route) => getUrl(route.key) === location.pathname
    );
    return !_.isEmpty(defNav) ? defNav.key : "";
  });

  const toggleNav = (key) => {
    if (activeNav !== key) {
      setActiveNav(key);
    }
  };

  return (
    <StaticCardForm
      formName={formName}
      headerActions={headerActions}
      labelName={labelName}
      body={
        <>
          {children}
          <Router history={history}>
            <CNav variant="pills" layout="fill" className="mb-3">
              {routes.map((route) => (
                <CNavItem>
                  <CNavLink
                    id={`nav-${route.key}`}
                    key={route.key}
                    to={getUrl(route.key)}
                    className="horizontal-tab"
                  >
                  {route.name}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNav>
            <Switch>
              {routes.map((route, key) => (
                <Route
                  key={key}
                  path={getUrl(route.key)}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <route.component
                      navigationTitle={route.name}
                      navigationKey={route.key}
                      {...route.propsWrapper}
                      {...props}
                      {...route.props}
                    />
                  )}
                />
              ))}
            </Switch>
          </Router>
        </>
      }
    />
  );
};

export default BasicHorTab;
