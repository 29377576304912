import { Authorization, Card } from "@banklampung/components";
import _ from "@banklampung/libs/lodash";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Wizard = ({ title, description, icon, link, backgroundIcon }) => {
  return (
    <Card
      bodyClassName="p-3 d-flex align-items-center"
      footerSlot={
        <>
          <Link
            to={link}
            className="btn-block text-muted d-flex justify-content-between align-items-center"
          >
            <span className="small font-weight-bold">Klik disini</span>

          </Link>
        </>
      }
    >
      <div className={`${backgroundIcon} p-3 mfe-3`}>
        {/* <CIconRaw size="xl" content={icon} /> */}
      </div>

      <div>
        <div className="text-value text-primary">{title}</div>
        <div className="text-muted text-uppercase font-weight-bold small">
          {description}
        </div>
      </div>
    </Card>
  );
};

const WizardLink = (props) => {
  const { permission } = props;

  return _.isEmpty(permission) ? (
    <Wizard {...props} />
  ) : (
    <Authorization permissions={permission}>
      <Wizard {...props} />
    </Authorization>
  );
};

WizardLink.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.oneOfType([String, Array]),
  link: PropTypes.string,
  backgroundIcon: PropTypes.string,
  permission: PropTypes.array,
};

WizardLink.defaultProps = {
  backgroundIcon: "bg-gradient-primary",
};

export default WizardLink;
