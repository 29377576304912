class RegexHelper {
  static toMaskingIdCard = (value) => value.replace(/\d(?=\d{12})/g, "*");

  static toAccountNumber = (value) =>
    value.replace(/(\d{3})(\d{2})(\d{2})(\d{5})(\d{1})/, "$1.$2.$3.$4.$5");

  static toLedgerNumber = (value) =>
    value.replace(/(\d{2})(\d{3})(\d{2})(\d{5})/, "$1.$2.$3.$4");

  static toAccountNumberMask = (value) =>
    value.replace(/(\d{3})(\d{2})(\d{2})(\d{5})(\d{1})/, "$1.$2.$3.XXXXX.$5");

  static toTaxpayerId = (value) =>
    value.replace(
      /(\d{2})(\d{3})(\d{3})(\d)(\d{3})(\d{3})/,
      "$1.$2.$3.$4-$5.$6"
    );

  static toTaxObjectNumber = (value) =>
    value.replace(
      /(\d{2})(\d{2})(\d{3})(\d{3})(\d{3})(\d{4})(\d)/,
      "$1.$2.$3.$4.$5-$6.$7"
    );

  static toNoticeTaxOfAssesmentNumber = (value) =>
    value.replace(/(\d{5})(\d{3})(\d{2})(\d{3})(\d{2})/, "$1/$2/$3/$4/$5");

  static toIdCard = (value) =>
    value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1.$2.$3.$4");

  static toCif = (value) => value.replace(/(\d{3})(\d{7})/, "$1.$2");

  static toPhone = (value) => value.replace(/(\d{3})(\d{8})/, "($1) - $2");

  static toHp = (value) => value.replace(/(\d{4})(\d{4})(\d{2})/, "$1-$2-$3");

  static toDate = (value) =>
    value.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$2-$1");

  static toPercentage = (value) => `${value.toFixed(2)}%`.replace(".", ",");

  static maskProtectPhoneNumber = (value) =>
    value.replace(/(\d{3})\d{6}/, "$1******");

  static maskProtectLast3Digit = (value) => value.replace(/(\d*)(\d{3})/, "$1***");

  static toActvSoftToken = (value) =>
    value.replace(
      /(\d{4})(\d{4})(\d{4})(\d{4})/,
      "$1.$2.$3.$4"
    );

  static to = (type, value) => {
    switch (type) {
      case "taxpayerId":
        return this.toTaxpayerId(value);

      case "ledgerNumber":
        return this.toLedgerNumber(value);

      case "accountNumber":
        return this.toAccountNumber(value);

      case "accountNumberMask":
        return this.toAccountNumberMask(value);

      case "taxObjectNumber":
        return this.toTaxObjectNumber(value);

      case "noticeTaxOfAssesmentNumber":
        return this.toNoticeTaxOfAssesmentNumber(value);

      case "idCard":
        return this.toIdCard(value);

      case "cif":
        return this.toCif(value);

      case "phone":
        return this.toPhone(value);

      case "hp":
        return this.toHp(value);

      case "date":
        return this.toDate(value);

      case "percentage":
        return this.toPercentage(value);

      case "protectPhoneNumber":
        return this.maskProtectPhoneNumber(value);

      case "protectLast3Digit":
        return this.maskProtectLast3Digit(value);

      case "actvSoftToken":
        return this.toActvSoftToken(value);

      default:
        return value;
    }
  };
}

export default RegexHelper;
