import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { SpinChase } from "@banklampung/components";

const LoadLazy = ({ children }) => {
  const loading = () => (
    <div className="animated pt-1 d-flex justify-content-center">
      <SpinChase />
    </div>
  );

  return <Suspense fallback={loading()}>{children}</Suspense>;
};

LoadLazy.propTypes = {
  children: PropTypes.any.isRequired
};

export default LoadLazy;
