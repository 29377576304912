const CITY_ENDPOINT = "aquilaria/cities";
const INQUIRY_ENDPOINT = "aquilaria/:cityCode/inquiry";
const PAYMENT_ENDPOINT = "aquilaria/:cityCode/payments";
const BULK_PBB_ENDPOINT = "aquilaria/:cityCode/bulk/pbb";
const SETTING_ENDPOINT = "aquilaria/:cityCode/settings";
const TYPE_TAX_ENDPOINT = "aquilaria/:cityCode/typetaxes";

export default {
  CITY_ENDPOINT,
  SETTING_ENDPOINT,
  INQUIRY_ENDPOINT,
  PAYMENT_ENDPOINT,
  BULK_PBB_ENDPOINT,
  TYPE_TAX_ENDPOINT
};
