import React from "react";
import PropTypes from "prop-types";

const SpanError = ({ error }) => (
  <em className="error invalid-feedback d-block">{error}</em>
);

SpanError.propTypes = {
  error: PropTypes.string.isRequired
};

export default SpanError;
