import { zoneDateTime, credentialKey, ocraKey } from "@banklampung/config";
import { DateTimeFormatter, LocalDateTime } from "@js-joda/core";
import CryptoJS from "crypto-js";
import "@js-joda/timezone";

class GlobalHelper {
  static leftRightCol = (arr, n) => {
    let chunkLength = Math.max(arr.length / n, 1);
    let chunks = [];
    for (let i = 0; i < n; i++) {
      if (chunkLength * (i + 1) <= arr.length)
        chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
  };

  static toTrxDate = (pattern = "yyyyMMddHHmmss") => {
    const d = LocalDateTime.now();
    const formatter = DateTimeFormatter.ofPattern(pattern).withLocale(
      `${zoneDateTime}`
    );

    return d.format(formatter).toString();
  };

  static toReferenceNumber = (productCode) => {
    productCode = productCode.replace(/\s/g, "");
    if (productCode.length > 8) {
      productCode = productCode.substr(0, 8);
    }
    const d = LocalDateTime.now();
    const formatter = DateTimeFormatter.ofPattern("HHmmssSSS").withLocale(
      `${zoneDateTime}`
    );

    return `${productCode.toUpperCase()}${d.format(formatter).toString()}`;
  };

  static toReferenceNumberV2 = (productCode, username) => {
    productCode = productCode.replace(/\s/g, "");
    if (productCode.length > 3) {
      productCode = productCode.substr(0, 3);
    }
    const d = LocalDateTime.now();
    const formatter = DateTimeFormatter.ofPattern("HHmmssSSS").withLocale(
      `${zoneDateTime}`
    );

    username = username.replace(/\s/g, "");
    if (username.length > 6) {
      username = username.substr(0, 6);
    }

    return `${productCode.toUpperCase()}${d
      .format(formatter)
      .toString()}${username.toUpperCase()}`;
  };

  static toReferenceNumberV3 = ({startStr, endStr, len=6}) => {
    const randString = this.toId(len);
    return `${startStr}${randString}${endStr}`;
  }

  static toId = (len) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let result = "";
    for (let i = 0; i < len; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  static getKeyType = (keyType) => {
    if(keyType == 'SFT0C124'){
      return ocraKey;
    }else if(keyType !== 'DEFAULT'){
      return keyType;
    }
    else{
      return credentialKey;
    }
  }

  static encryptTripleDES = (plainText, keyType="DEFAULT") => {
    const key = this.getKeyType(keyType)
    const encrypt = CryptoJS.TripleDES.encrypt(
      CryptoJS.enc.Utf8.parse(plainText),
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.ZeroPadding,
      }
    );
    
    const newEncrypt = encrypt.toString(CryptoJS.format.Hex);
    return newEncrypt.toUpperCase();
  };

  static decyptTripleDES = (ciphertext, keyType="DEFAULT") => {
    const key = this.getKeyType(keyType)
    const decrypted = CryptoJS.TripleDES.decrypt(
      {
        ciphertext: CryptoJS.enc.Hex.parse(ciphertext),
      },
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.NoPadding,
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

}

export default GlobalHelper;
