import React from "react";
import { CSidebarNavItem, CNavLink } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomSidebarNavItem = (props) => {
    const { name, to, faIcon, icon } = props;
    return (
        <CSidebarNavItem>
            <CNavLink to={to} className={"c-sidebar-nav-link"}>
                {faIcon ? (
                    <FontAwesomeIcon 
                        icon={faIcon} 
                        className="c-sidebar-nav-icon"
                    />
                ) : (
                    <CIcon className="c-sidebar-nav-icon" name={icon} />
                )}
                { name }
            </CNavLink>
        </CSidebarNavItem>
    )
}
CustomSidebarNavItem.defaultProps = {};
export default React.memo(CustomSidebarNavItem);