import {
  Card,
  FooterSubmitForm,
  LoaderButton,
  LoadLazy,
} from "@banklampung/components";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { CCol, CContainer, CRow } from "@coreui/react";

const BasicHorForm = ({
  children,
  onSubmit,
  formName,
  formLabel,
  formSubLabel,
  formLabelOverride,
  body,
  buttonSubmit,
  slotButton,
  buttonAlign,
  propsCard,
  outsideEnd,
  delayBtnActive,
  hideButton
}) => {
  const refBtnSubmit = useRef();
  const [disabled, setDisabled] = useState(false);
  const [row, setRow] = useState();

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
      }, delayBtnActive);
    }
  }, [disabled]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisabled(true);

    if (!buttonSubmit.disabled) {
      refBtnSubmit.current.click();
    }
  };

  const rowFilter = useMemo(() => {
    // for(var i=0; i<(body.props.children).length; i++) {
    //     return i;
    // }
    const row = body.props.children.map((val, index) => {
      if (index % 4 == 3) {
        return "this";
      }
    })

    return row;
  });
  
  return (
    <Card
      headerSlot={
        <>
          {formLabelOverride ? (
            <strong>{formLabelOverride}</strong>
          ) : (
            <strong>
              Form {formLabel} <small>{formSubLabel}</small>
            </strong>
          )}
        </>
      }
      footerSlot={
        <>
          {hideButton == false && (
            <FooterSubmitForm
              button={
                slotButton ? (
                  slotButton
                ) : (
                  <LoaderButton
                    {...buttonSubmit}
                    type="button"
                    disabled={disabled}
                    onClick={handleSubmit}
                    size="md"
                  />
                  // <CContainer className="pl-0">
                  //   <CRow>
                  //     <CCol lg="12" className="pl-5 pr-5">
                  //       <LoaderButton
                  //         {...buttonSubmit}
                  //         type="button"
                  //         disabled={disabled}
                  //         onClick={handleSubmit}
                  //         size="md btn-block"
                  //       />
                  //     </CCol>
                  //   </CRow>
                  // </CContainer>

                )
              }
              align={buttonAlign}
            />
          )}
        </>
      }
      {...propsCard}
    >
      <LoadLazy>
        <form onSubmit={onSubmit} name={formName} id={formName}>
          {body || children}


          <button
            type="submit"
            ref={refBtnSubmit}
            style={{
              display: "none",
            }}
          ></button>
        </form>
        {outsideEnd}
        <div className="required">* Mandatory</div>
      </LoadLazy>
    </Card>
  );
};

BasicHorForm.propTypes = {
  onSubmit: PropTypes.any.isRequired,
  formName: PropTypes.string.isRequired,
  body: PropTypes.any,
  buttonSubmit: PropTypes.any.isRequired,
};

BasicHorForm.defaultProps = {
  delayBtnActive: 2000,
  propsCard: {},
  buttonSubmit: {
    disabled: false,
  },
  hideButton: false
};

export default BasicHorForm;
