import React from "react";
import PropTypes from "prop-types";

const StaticForm = ({ formName, body }) => {
  return (
    <>
      <h5>{formName}</h5>
      <hr />
      {body}
    </>
  );
};

StaticForm.propTypes = {
  formName: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired
};

export default StaticForm;
