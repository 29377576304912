import { LoadLazy } from "@banklampung/components";
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react";
import React, { useState } from "react";

const BasicTab = (props) => {
  const { routes } = props;
  const [activeTab, setActiveTab] = useState(() => routes[0].key);
  const toggle = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <CNav variant="tabs" justified>
        {routes.map((route, key) => (
          <CNavItem key={key}>
            <CNavLink
              id={route.key}
              active={activeTab === route.key}
              onClick={() => {
                toggle(route.key);
              }}
            >
              {route.name}
            </CNavLink>
          </CNavItem>
        ))}
      </CNav>
      <CTabContent className="mt-2">
        {routes.map((route, key) => (
          <CTabPane active={activeTab === route.key} key={key}>
            <LoadLazy>
              {React.isValidElement(route.component) ? (
                route.component
              ) : (
                <route.component {...route.props} />
              )}
            </LoadLazy>
          </CTabPane>
        ))}
      </CTabContent>
    </>
  );
};

export default BasicTab;
