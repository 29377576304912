import { ColTwo, StaticCardForm } from "@banklampung/components";
import _ from "@banklampung/libs/lodash";
import { history } from "@banklampung/utils";
import { CListGroup, CListGroupItem } from "@coreui/react";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

const BasicVertTab = (props) => {
  const { formName, routes, location, match, currentNav, children, headerActions } = props;

  const getUrl = (tabType) => {
    const params = match.params;
    let path = match.path;

    _.forEach(params, function (value, key) {
      path = path.replaceAll(`:${key}`, key === "tabType" ? tabType : value);
    });

    return path;
  };

  const [activeNav, setActiveNav] = useState(() => {
    const defNav = _.find(
      routes,
      (route) => getUrl(route.key) === location.pathname
    );
    return !_.isEmpty(defNav) ? defNav.key : "";
  });

  const toggleNav = (key) => {
    if (activeNav !== key) {
      setActiveNav(key);
    }
  };

  useEffect(() => {
    if (currentNav) {
      toggleNav(currentNav);
      let linkId = `nav-${currentNav}`;
      let link = document.getElementById(linkId);

      if (link) {
        link.click();
      }
    }
  }, [currentNav]);

  return (
    <StaticCardForm
      formName={formName}
      body={
        <>
          {children}
          <Router history={history}>
            <ColTwo
              propsColA={{ md: 3 }}
              propsColB={{ md: 9 }}
              childA={
                <CListGroup id="list-tab" role="tablist" className="mb-2">
                  {routes.map((route) => (
                    <CListGroupItem
                      id={`nav-${route.key}`}
                      key={route.key}
                      to={getUrl(route.key)}
                      action
                    >
                      {route.name}
                    </CListGroupItem>
                  ))}
                </CListGroup>
              }
              childB={
                <Switch>
                  {routes.map((route, key) => (
                    <Route
                      key={key}
                      path={getUrl(route.key)}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <route.component
                          navigationTitle={route.name}
                          navigationKey={route.key}
                          {...route.propsWrapper}
                          {...props}
                          {...route.props}
                        />
                      )}
                    />
                  ))}
                </Switch>
              }
            />
          </Router>
        </>
      }
      headerActions={headerActions}
    />
  );
};

export default BasicVertTab;
