const months = [
  "",
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

class DateFormatter {
  
  static getMaxDate = ({ substractYear = 0 }) => {
    const now = new Date();

    now.setFullYear(now.getFullYear() - substractYear);

    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();

    if (day < 10) {
      day = `0${day}`;
    }

    if (month < 10) {
      month = `0${month}`;
    }

    return `${year}-${month}-${day}`;
  };

  static to = (pattern, str) => {
    switch (pattern) {
      case "d F Y": {
        let day = str.split("-")[0];
        let month = str.split("-")[1];
        let year = str.split("-")[2];

        return `${day} ${months[Math.abs(month)]} ${year}`;
      }

      case "d F Y h:i:s": {
        let date = str.split(" ")[0];

        let day = date.split("-")[0];
        let month = date.split("-")[1];
        let year = date.split("-")[2];

        let time = str.split(" ")[1];

        let hour = time.split(":")[0];
        let minute = time.split(":")[1];
        let second = time.split(":")[2];

        return `${day} ${
          months[Math.abs(month)]
        } ${year} ${hour}:${minute}:${second}`;
      }

      case "d F Y h:i": {
        let date = str.split(" ")[0];

        let day = date.split("-")[0];
        let month = date.split("-")[1];
        let year = date.split("-")[2];

        let time = str.split(" ")[1];

        let hour = time.split(":")[0];
        let minute = time.split(":")[1];

        return `${day} ${
          months[Math.abs(month)]
        } ${year} ${hour}:${minute}`;
      }

      case "h:i:s": {
        let time = str.split(" ")[1];

        let hour = time.split(":")[0];
        let minute = time.split(":")[1];
        let second = time.split(":")[2];

        return `${hour}:${minute}:${second}`;
      }

      case "h:i": {
        let time = str.split(" ")[1];

        let hour = time.split(":")[0];
        let minute = time.split(":")[1];

        return `${hour}:${minute}`;
      }

      default:
        return "";
    }
  };
}

export default DateFormatter;
