import { CCard, CCardBody, CCardFooter, CCardHeader } from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";

const Card = (props) => {
  const {
    children,
    headerSlot,
    footerSlot,
    ...propsCard
  } = props;

  return (
    <CCard {...propsCard}>
      {headerSlot && <CCardHeader>{headerSlot}</CCardHeader>}
      <CCardBody>{children}</CCardBody>
      {footerSlot && <CCardFooter>{footerSlot}</CCardFooter>}
    </CCard>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  footerSlot: PropTypes.node,
  headerSlot: PropTypes.node,
};

export default Card;
