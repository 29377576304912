const HasError = (form, inputName, method) => {
  return (
    form &&
    form.errors &&
    form.errors[inputName] &&
    form.errors[inputName][method]
  );
};

export default HasError;
