const FUND_ENDPOINT = "aquatica/funds";
const OPEN_ACCOUNT_ENDPOINT = "aquatica/accounts";
const TREMAR_ENDPOINT = "aquatica/tremars";
const AREA_ENDPOINT = "aquatica/areas";
const LIST_TRF_TELLER = "aquatica/tellers/transfer-teller";
const GET_DETAIL_TRF_TELLER = "aquatica/tellers/transfer-teller/:id";
const SUBMIT_TRF_TELLER = "aquatica/tellers/transfer-teller/submit";

export default {
    FUND_ENDPOINT,
    OPEN_ACCOUNT_ENDPOINT,
    TREMAR_ENDPOINT,
    AREA_ENDPOINT,
    LIST_TRF_TELLER,
    GET_DETAIL_TRF_TELLER,
    SUBMIT_TRF_TELLER,
};