import React, { useEffect } from "react";
import { FieldGroup, LoaderButton } from "@banklampung/components";
import { appVersion } from "@banklampung/config";
import { useForm, usePostApi } from "@banklampung/hooks";
import { CContainer, CForm, CRow } from "@coreui/react";
import { LocalStorage } from "@banklampung/utils";
import { notEmpty } from "@banklampung/helper";
import { clientKey } from "@banklampung/config";
import PropTypes from "prop-types";
import CIcon from "@coreui/icons-react";
import { AuthEndpoint } from "app/endpoint";
import * as GlobalActions from "@banklampung/store/actions";
import { useDispatch } from "react-redux";

const MultiFactorAuth = (props) => {
    const { companyName, departementName, logo, onSuccess,  children } = props;
    const dispatch = useDispatch();
    const MFAToken = LocalStorage.getMFAToken();

    const { form, handleChange, handleSubmit } = useForm({
        initialState: {
          code: "",
        },
        onSubmit: () => {
          onSubmitMFA();
        },
    });

    const onSubmitMFA = () => {
        (async () => {
          const url = `/${AuthEndpoint.AUTH_ENDPOINT}`;          
          const body = {
            grant_type: 'mfa',
            mfa_token: MFAToken,
            mfa_code: form.code,
          };
    
          doSubmit({
            url: url,
            body: body,
            headers: {
              "X-BLPG-Key": clientKey
            }
          });
        })();
    };

    const [{ isLoading: isLoading }, doSubmit] = usePostApi({
        errorHandler: false,
        onSuccess: ({ payload }) => {
          onSuccess(payload);
        },
        onError:({ response={} }) => {
          const { data={} } = response;
          console.log("debug-data", data);
          if(data.error_code == "mfa_code_invalid"){
            dispatch(
              GlobalActions.openDialog({
                  icon: "error",
                  title: `Gagal`,
                  text: `Kode verifikasi tidak sesuai`,
                  showConfirmButton: true,
                  onConfirm: () => {
                      dispatch(GlobalActions.closeDialog());
                  },
              })
            );
          }
        }
    });

    return (
        <CContainer>
            <CRow className="justify-content-center">
                
                {children}

                <CForm
                    onSubmit={handleSubmit}
                    name="formLogin"
                    className="d-flex flex-column"
                >
                    <CIcon
                    name={logo}
                    width="220"
                    height="80"
                    className="align-self-center"
                    alt="logo-bank-lampung"
                    />
                    <h2 className="mt-2 align-self-center">
                        {"Masukan Kode Verifikasi"}
                    </h2>

                    <h6 className="align-self-center">
                        {"Masukan Kode dari aplikasi Authenticator yang terhubung dengan Akun"}
                    </h6>

                    <FieldGroup
                    id="code"
                    label=""
                    placeholder="Masukan kode verifikasi"
                    type="input"
                    validate={`["required", "len"]`}
                    param="[6,12]"
                    onChange={handleChange}
                    value={form.code}
                    invalid={form.invalids.code}
                    errorMessage={form.errorMessages.code}
                    data-is-number
                    />

                    <LoaderButton
                    propsIcon={{ name: "cil-paper-plane" }}
                    label="Verifikasi"
                    isLoading={isLoading}
                    className="mt-1"
                    />

                    <div className="d-flex flex-column mt-5 align-self-center align-items-center">
                        <span>
                            {`${new Date().getFullYear()} @ ${departementName} - ${companyName}`}
                        </span>
                        <small className="text-muted">{`Versi ${appVersion}`}</small>
                    </div>
                </CForm>
            </CRow>
        </CContainer>
    );
};

MultiFactorAuth.propTypes = {
  companyName: PropTypes.string,
  departementName: PropTypes.string,
  logo: PropTypes.string,
};

MultiFactorAuth.defaultProps = {
  companyName: "PT Bank Lampung",
  departementName: "Divisi Teknologi Informasi",
};

export default MultiFactorAuth;
