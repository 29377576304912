const AUTH_ENDPOINT = "auth/oauth/token";
const USERS_ENDPOINT = "auth/users";
const EMPLOYES_ENDPOINT = "auth/employes";
const DEPARTMENTS_ENDPOINT = "auth/departments";

export default {
  AUTH_ENDPOINT,
  USERS_ENDPOINT,
  EMPLOYES_ENDPOINT,
  DEPARTMENTS_ENDPOINT
};
