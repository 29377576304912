import React from "react";
import PropTypes from "prop-types";
import { CFormGroup, CLabel } from "@coreui/react";

const StaticFieldGroup = (props) => {
  const {
    label,
    value,
    help,
    content,
    classLabel,
    classValue,
    classForm,
    styleForm,
    classContent,
  } = props;
  return (
    <CFormGroup className={classForm} style={styleForm}>
      <CLabel className={classLabel}>{label}</CLabel>
      <p 
        className={`form-control form-address ${classValue || ""}`}
        style={{borderColor: "rgb(233, 236, 239)", backgroundColor: "rgb(248, 249, 250)"}}
      >
        {value || " "}
      </p>
      {content && <div className={classContent || ""}>{content}</div>}
      {help && <small className="form-text text-muted">{help}</small>}
    </CFormGroup>
  );
};

StaticFieldGroup.propTypes = {
  label: PropTypes.string.isRequired,
};

export default StaticFieldGroup;
