import { useEffect, useState } from "react";

const Delayed = ({ waitBeforeShow, children }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};

Delayed.defaultProps = {
  waitBeforeShow: 500,
};

export default Delayed;
