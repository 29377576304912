import {
  BasicPagination,
  PaginationDropdown,
  SpinChase,
} from "@banklampung/components";
import { useEffectObj } from "@banklampung/hooks";
import CIcon from "@coreui/icons-react";
import { CListGroup } from "@coreui/react";
import React, { useState } from "react";

const CardList = (props) => {
  const {
    children,
    itemCount,
    pageIndex,
    pageSize,
    pageCount,
    params,
    fetchData,
    loading,
    reload,
    paginationable,
  } = props;

  const [page, setPage] = useState(pageIndex);
  const [size, setSize] = useState(pageSize);

  useEffectObj(() => {
    fetchData({
      page: page,
      item_per_page: size,
      ...params,
    });
  }, [
    {
      pageIndex: page,
      pageSize: size,
      reload: reload,
      ...params,
    },
  ]);

  return (
    <>
      {paginationable && (
        <div className="d-flex flex-row justify-content-between mb-2">
          <PaginationDropdown
            pages={[5, 10, 25]}
            pageSize={size}
            onChange={(value) => {
              setSize(value);
            }}
          />
        </div>
      )}

      {loading ? (
        <div className="animated d-flex justify-content-center">
          <SpinChase />
        </div>
      ) : itemCount == 0 ? (
        <div className="animated d-flex flex-column justify-content-center align-items-center">
          <CIcon name="cilBan" height="40" alt="icon-empty" />
          <p>Tidak ada data tersedia</p>
        </div>
      ) : (
        <CListGroup className="mb-3">{children}</CListGroup>
      )}

      {paginationable && !loading && pageCount > 0 && (
        <BasicPagination
          pageIndex={page}
          pageSize={size}
          pageCount={pageCount}
          onActivePageChange={(value) => {
            if (value !== 0) {
              setPage(value);
            }
          }}
        />
      )}
    </>
  );
};

CardList.defaultProps = {
  paginationable: true,
  pageIndex: 1,
  pageSize: 5,
  pageCount: 0,
  itemCount: 0,
};

export default CardList;
