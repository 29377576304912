import { ContentLoading } from "@banklampung/components";
import { notEmpty } from "@banklampung/helper";
import _ from "@banklampung/libs/lodash";
import { hostInstance } from "@banklampung/services";
import * as GlobalActions from "@banklampung/store/actions";
import fetchReducer from "@banklampung/store/reducers/fetch.reducer";
import camelcaseKeys from "camelcase-keys";
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";

const useApi = ({
  method,
  initialAction = "",
  initialUrl = "",
  initalParams = {},
  initialBody = {},
  initialHeaders = { "Content-Type": "application/json" },
  initialResponseType = "json",
  progressDialog = {},
  delay = 0,
  convertCamelCase = true,
  responseFormatted = "default",
  errorHandler = true,
  onSuccess,
  onError,
}) => {
  const dispatchRedux = useDispatch();
  const [{ url, responseType, headers, params, body, auth }, setFetchData] =
    useState({
      url: initialUrl,
      responseType: initialResponseType,
      headers: initialHeaders,
      params: initalParams,
      body: initialBody,
      auth: undefined,
    });
  const [state, dispatch] = useReducer(fetchReducer, {
    isLoading: false,
    isError: false,
  });

  progressDialog = {
    show: progressDialog.show || false,
    autoClose:
      progressDialog.autoClose === undefined ? true : progressDialog.autoClose,
    title: progressDialog.title || "",
    message: progressDialog.message || "",
  };

  useEffect(() => {
    let didCancel = false;

    if (url !== "") {
      setTimeout(() => {
        dispatch({ type: GlobalActions.FETCH_INIT });
        if (progressDialog.show) {
          dispatchRedux(
            GlobalActions.openDialog({
              showConfirmButton: false,
              html: (
                <ContentLoading
                  title={progressDialog.title}
                  message={progressDialog.message}
                />
              ),
            })
          );
        }

        hostInstance
          .request({
            method: method,
            url: url,
            responseType: responseType || "json",
            params: params,
            data: body,
            headers: {
              ...headers,
            },
            auth: auth,
            dialogHandlerEnabled: progressDialog.show,
            errorHandlerEnabled: errorHandler,
          })
          .then((response) => {
            const data = response.data || {}
            if (!didCancel) {
              if (progressDialog.show && progressDialog.autoClose) {
                dispatchRedux(GlobalActions.closeDialog());
              }

              if (_.isEmpty(data)) {
                onSuccess({});
              }

              let responseData = data;

              if (responseFormatted === "default") {
                responseData = data.data;
              }

              let payload = convertCamelCase
                ? camelcaseKeys(responseData, {
                    deep: true,
                  })
                : responseData;

              let response = {
                payload: payload,
              };

              const { paging, aggregate, message, description } = data;

              if (message) {
                response["message"] = message;
              }

              if (description) {
                response["description"] = description;
              }

              if (paging) {
                response["paging"] = camelcaseKeys(paging, {
                  deep: true,
                });
              }

              if (aggregate) {
                response["aggregate"] = aggregate;
              }

              dispatch({ type: GlobalActions.FETCH_SUCCESS });

              if (initialAction) {
                dispatchRedux({
                  type: initialAction,
                  ...response,
                });
              }

              if (onSuccess) {
                onSuccess(response);
              }
            }
          })
          .catch((error) => {
            if (!didCancel) {
              dispatch({ type: GlobalActions.FETCH_FAILURE });

              if (progressDialog.show) {
                dispatchRedux(GlobalActions.closeDialog());
              }

              if (onError) {
                onError(error);
              }

              if (notEmpty(error.response) && error.response.status === 401) {
                dispatchRedux(GlobalActions.logoutUser());
              }
            }
          });

        return () => {
          didCancel = true;
        };
      }, delay);
    }
  }, [url, responseType, params, body, headers, auth]);

  return [state, setFetchData];
};

export default useApi;
