import { jwtService } from "@banklampung/services";
import { useEffect, useState } from "react";
import camelcaseKeys from "camelcase-keys";

import { EventSourcePolyfill } from "event-source-polyfill";

global.EventSource = EventSourcePolyfill;

const useEventSource = ({ initalUrl, onOpen, onMessage, onError }) => {
  const [message, setMessage] = useState(null);
  const [url, setEventUrl] = useState(initalUrl);

  useEffect(() => {
    let eventSource = null;

    if (url) {
      const accessToken = jwtService.getAccessToken();

      const options = {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      eventSource = new EventSource(url, options);

      eventSource.onopen = () => {
        console.log("connection is established");
        if (onOpen) {
          onOpen();
        }
      };

      eventSource.onmessage = (event) => {
        const data = camelcaseKeys(JSON.parse(event.data), {
          deep: true,
        });
        console.log("id: " + event.lastEventId + " data: ", data);
        setMessage(data);
        if (onMessage) {
          onMessage(data);
        }
      };

      eventSource.onerror = (event) => {
        console.log(
          "connection state: " +
            eventSource.readyState +
            ", error: " +
            event.error
        );
        if (onError) {
          onError();
        }
      };
    }

    return () => {
      if(eventSource) {
        eventSource.close();
      }
    };
  }, [url]);

  return [message, setEventUrl];
};

export default useEventSource;
