import { ColTwo, LoadLazy, StaticCardForm } from "@banklampung/components";
import _ from "@banklampung/libs/lodash";
import { history } from "@banklampung/utils";
import { CCollapse, CListGroup, CListGroupItem } from "@coreui/react";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.scss";

const Navigation = memo((props) => {
  const { navs, location, currentNav, currentGroup } = props;

  const [activeGroup, setActiveGroup] = useState(() => {
    const defGroup = _.find(navs, (nav) => {
      return _.find(nav.routes, (route) => route.to === location.pathname);
    });
    return !_.isEmpty(defGroup) ? defGroup.groupKey : "";
  });
  const [activeNav, setActiveNav] = useState(() => {
    const defNav = _.find(
      _.spread(_.union)(navs.map((nav) => nav.routes)),
      (route) => route.to === location.pathname
    );
    return !_.isEmpty(defNav) ? defNav.key : "";
  });

  const toggleGroup = (key) => {
    if (activeGroup !== key) {
      setActiveGroup(key);
    }
  };

  const toggleNav = (key) => {
    if (activeNav !== key) {
      setActiveNav(key);
    }
  };

  useEffect(() => {
    if (currentNav) {
      toggleNav(currentNav);
      let linkId = `nav-${currentNav}`;
      let link = document.getElementById(linkId);

      if (link) {
        link.click();
      }
    }
  }, [currentNav]);

  useEffect(() => {
    if (currentGroup) {
      toggleGroup(currentGroup);
    }
  }, [currentGroup]);

  return (
    <CListGroup>
      {navs.map(({ groupKey, name, routes }) => {
        let isGroupActive = activeGroup === groupKey;

        return (
          <CListGroupItem key={`a-${groupKey}`} className="mb-2">
            <h5
              className={`list-group-item-heading pt-2 pr-2 pl-1 block ${
                isGroupActive && "text-primary"
              }`}
              onClick={() => toggleGroup(groupKey)}
            >
              {name}
            </h5>
            <CCollapse show={isGroupActive} key={`zz-${groupKey}`}>
              <div className="list-group-item-text mt-2">
                <CListGroup id="list-tab" role="tablist" key={`b-${groupKey}`}>
                  {routes.map((route) => (
                    <CListGroupItem
                      id={`nav-${route.key}`}
                      key={`${groupKey}-${route.key}`}
                      onClick={() => toggleNav(route.key)}
                      active={activeNav === route.key}
                      to={route.to}
                      className={classNames({ invalid: route.invalid })}
                      action
                    >
                      {route.name}
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </div>
            </CCollapse>
          </CListGroupItem>
        );
      })}
    </CListGroup>
  );
});

const Group = memo((props) => {
  const { nav, toggleNav, toggleGroup } = props;

  return (
    <LoadLazy>
      <Switch>
        {nav.routes.map((route) => {
          return route.component ? (
            <Route
              key={route.key}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={(props) => (
                <route.component
                  {...props}
                  {...nav.propsWrapper}
                  {...route.props}
                  navigationTitle={route.name}
                  navigationKey={route.key}
                  toggleNav={toggleNav}
                  toggleGroup={toggleGroup}
                />
              )}
            />
          ) : null;
        })}
      </Switch>
    </LoadLazy>
  );
});

const BasicVertMultiTab = (props) => {
  const { formName, navs, propsCard, defActiveNav, defActiveGroup } = props;
  const [activeNav, setActiveNav] = useState();
  const [activeGroup, setActiveGroup] = useState();

  const toggleNav = (key) => {
    setActiveNav(key);
  };

  const toggleGroup = (key) => {
    setActiveGroup(key);
  };

  useEffect(() => {
    if (defActiveNav) {
      toggleNav(defActiveNav);
    }
  }, [defActiveNav]);

  useEffect(() => {
    if (defActiveGroup) {
      toggleGroup(defActiveGroup);
    }
  }, [defActiveGroup]);

  return (
    <StaticCardForm
      propsCard={propsCard}
      formName={formName}
      body={
        <Router history={history}>
          <ColTwo
            propsColA={{ md: 3 }}
            propsColB={{ md: 9 }}
            childA={
              <Navigation
                {...props}
                currentNav={activeNav}
                currentGroup={activeGroup}
              />
            }
            childB={
              <LoadLazy>
                <Switch>
                  {navs.map((nav, key) => {
                    return (
                      <Route
                        key={key}
                        path={nav.path}
                        render={(props) => (
                          <Group
                            nav={nav}
                            toggleNav={toggleNav}
                            toggleGroup={toggleGroup}
                          />
                        )}
                      />
                    );
                  })}
                </Switch>
              </LoadLazy>
            }
          />
        </Router>
      }
    />
  );
};

BasicVertMultiTab.propTypes = {
  formName: PropTypes.string,
  navs: PropTypes.array,
};

BasicVertMultiTab.defaultProps = {
  navs: [],
  propsCard: {},
};

export default memo(BasicVertMultiTab);
