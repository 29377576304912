import React from "react";
import PropTypes from "prop-types";
import { LoadLazy, Card } from "@banklampung/components";

const StaticCardForm = ({
  formName,
  body,
  footer,
  headerActions,
  labelName,
  propsCard,
}) => {
  return (
    <Card
      headerSlot={
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="row">
            <div className="col-xl-12">
              <strong>{formName}</strong>
            </div>
            {labelName && (
            <div className="col-xl-12 pt-1">
                <p className="mb-0">{labelName}</p>
            </div>
            )}
          </div>
          
          {headerActions && (
            <div className="card-header-actions">{headerActions}</div>
          )}
        </div>
      }
      footerSlot={footer}
      {...propsCard}
    >
      <LoadLazy>{body}</LoadLazy>
    </Card>
  );
};

StaticCardForm.propTypes = {
  formName: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
  headerActions: PropTypes.any,
};

StaticCardForm.defaultProps = {
  propsCard: {},
};

export default StaticCardForm;
