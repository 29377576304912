import _ from "@banklampung/libs/lodash";
import { FormValidator } from "@banklampung/utils";
import { useCallback, useState } from "react";

const useForm = ({
  initialState,
  initialInput = [],
  onChange,
  onChangeSelect,
  onSubmit,
}) => {
  const [form, setForm] = useState(() => ({
    invalids: {},
    errorMessages: {},
    errors: {},
    ...initialState,
  }));

  const handleChange = useCallback((event) => {
    event.persist();
    const {
      input,
      value,
      result,
      hasError,
      errorMessage,
    } = FormValidator.validateOnChange(event);

    setForm((form) => ({
      ...form,
      [input.name]: value,
      errors: {
        ...form.errors,
        [input.name]: result,
      },
      invalids: {
        ...form.invalids,
        [input.name]: hasError,
      },
      errorMessages: {
        ...form.errorMessages,
        [input.name]: errorMessage,
      },
    }));

    if (onChange) {
      onChange({
        name: input.name,
        value: value,
      });
    }
  }, []);

  const handleChangeSelect = useCallback((selectedOpt, inputName) => {
    const {
      result,
      hasError,
      errorMessage,
    } = FormValidator.validateOnSelectSingle(selectedOpt);

    setForm((form) => ({
      ...form,
      [inputName]: selectedOpt,
      errors: {
        ...form.errors,
        [inputName]: result,
      },
      invalids: {
        ...form.invalids,
        [inputName]: hasError,
      },
      errorMessages: {
        ...form.errorMessages,
        [inputName]: errorMessage,
      },
    }));

    if (onChangeSelect) {
      onChangeSelect({
        name: inputName,
        value: selectedOpt,
      });
    }
  }, []);

  const hasError = (inputName, method) => {
    return (
      form &&
      form.errors &&
      form.errors[inputName] &&
      form.errors[inputName][method]
    );
  };

  const resetForm = useCallback(() => {
    setForm(initialState);
  }, [initialState]);

  const setInForm = useCallback((name, value) => {
    setForm((form) => _.setIn(form, name, value));
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
      }

      if (onSubmit) {
        const formField = event.target;

        const inputs = [...formField.elements]
          .filter((i) => {
            const {
              errors,
              invalids,
              errorMessages,
              hasError,
              ...inputNames
            } = form;

            return Object.keys(inputNames).includes(i.name);
          })
          .map((i) => {
            if (initialInput.includes(i.name)) {
              i.dataset.validate = `["required"]`;
            }
            return i;
          });

        const {
          errors,
          hasError,
          invalids,
          errorMessages,
        } = FormValidator.bulkValidate(inputs);

        setForm((form) => ({
          ...form,
          hasError: hasError,
          errors,
          invalids,
          errorMessages,
        }));

        if (!hasError) {
          onSubmit();
        }
      }
    },
    [onSubmit]
  );

  return {
    form,
    handleChange,
    handleChangeSelect,
    handleSubmit,
    hasError,
    resetForm,
    setForm,
    setInForm,
  };
};

export default useForm;
