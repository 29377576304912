export const brandFullNegative = ['1300 320', `
<path d="M92.6164 38.5152L73.9407 43.5191L82.6976 76.2016L101.373 71.1977L92.6164 38.5152Z" fill="white"/>
<path d="M361.635 34L342.959 39.0039L351.716 71.6863L370.392 66.6824L361.635 34Z" fill="white"/>
<path d="M432.288 215.123L292.357 252.664L267.968 161.604L286.634 156.6L306 228.975L427.29 196.438L432.288 215.123Z" fill="white"/>
<path d="M314.737 64.1396L23.1455 142.696L1.26526 60.6185L19.9335 55.5919L36.8146 118.923L309.738 45.3686L314.737 64.1396Z" fill="white"/>
<path d="M419.648 166.204L328.582 190.643L308.565 115.85L23.6912 192.264L31.8138 222.631L239.643 166.905L244.647 185.585L18.1552 246.344L0 178.568L322.248 92.1364L342.265 166.93L414.644 147.523L419.648 166.204Z" fill="white"/>
<path d="M557.04 52.432C566.616 48.632 572.544 39.36 572.544 28.264C572.544 11.392 559.928 0.599976 540.168 0.599976H492.896V107H540.168C562.816 107 576.192 96.36 576.192 78.272C576.192 66.112 568.744 56.08 557.04 52.432ZM540.472 9.11197C554.76 9.11197 563.728 16.56 563.728 28.872C563.728 41.184 555.064 48.48 540.472 48.48H501.864V9.11197H540.472ZM540.928 98.488H501.864V56.84H540.928C557.192 56.84 567.224 64.744 567.224 77.512C567.224 90.584 557.344 98.488 540.928 98.488Z" fill="white"/>
<path d="M655.421 31.912V46.96C649.645 36.928 638.093 30.544 624.261 30.544C602.221 30.544 586.717 46.656 586.717 69.456C586.717 92.256 602.221 108.368 624.261 108.368C638.093 108.368 649.645 101.984 655.421 92.104V107H663.477V31.912H655.421ZM625.021 100.312C607.693 100.312 595.381 88 595.381 69.456C595.381 50.912 607.693 38.6 625.021 38.6C642.501 38.6 654.813 50.912 654.813 69.456C654.813 88 642.501 100.312 625.021 100.312Z" fill="white"/>
<path d="M719.996 30.848C707.684 30.848 697.196 36.32 691.268 45.44L691.116 31.912H683.212V107H691.876V65.808C691.876 50.608 702.972 38.904 719.084 38.904C734.436 38.904 743.1 48.024 743.1 64.592V107H751.764V64.136C751.764 43.312 739.756 30.848 719.996 30.848Z" fill="white"/>
<path d="M829.514 107L787.562 68.392L824.954 31.912H813.554L779.05 65.96V0.599976H770.386V107H779.05V71.584L817.506 107H829.514Z" fill="white"/>
<path d="M491.94 253H576.94V231.41H515.4V134H491.94V253Z" fill="white"/>
<path d="M652.385 169.02L650.855 179.05C644.055 171.4 633.685 167.15 621.785 167.15C597.645 167.15 580.985 185.17 580.985 211.01C580.985 236.85 597.645 254.87 621.785 254.87C633.685 254.87 643.885 250.62 650.855 242.8L652.385 253H671.935V169.02H652.385ZM626.375 235.15C612.945 235.15 603.425 225.12 603.425 211.01C603.425 196.9 612.945 186.87 626.375 186.87C639.975 186.87 649.495 196.9 649.495 211.01C649.495 225.12 639.975 235.15 626.375 235.15Z" fill="white"/>
<path d="M790.904 167.32C777.984 167.32 768.124 172.08 761.494 181.26C756.394 172.59 746.874 167.32 735.314 167.32C725.454 167.32 716.954 171.57 711.344 178.88L709.814 169.02H690.264V253H712.874V206.76C712.874 194.86 720.184 187.04 731.064 187.04C741.094 187.04 746.704 193.67 746.704 205.57V253H769.144V206.76C769.144 194.86 776.454 187.04 787.334 187.04C797.364 187.04 802.974 193.67 802.974 205.57V253H825.584V203.02C825.584 180.75 812.324 167.32 790.904 167.32Z" fill="white"/>
<path d="M892.902 167.15C881.002 167.15 870.632 171.4 863.832 179.05L862.302 169.02H842.752V290.57H865.362V244.33C872.162 251.13 881.852 254.87 892.902 254.87C917.042 254.87 933.702 236.85 933.702 211.01C933.702 185.17 917.042 167.15 892.902 167.15ZM888.312 235.15C874.712 235.15 865.192 225.12 865.192 211.01C865.192 196.9 874.712 186.87 888.312 186.87C901.742 186.87 911.262 196.9 911.262 211.01C911.262 225.12 901.742 235.15 888.312 235.15Z" fill="white"/>
<path d="M1006.26 169.02V213.05C1006.26 225.97 998.27 234.98 986.2 234.98C975.32 234.98 968.35 227.5 968.35 214.58V169.02H945.74V215.94C945.74 239.74 959.51 254.7 980.42 254.7C991.47 254.7 1001.16 250.62 1007.79 243.31L1009.32 253H1028.87V169.02H1006.26Z" fill="white"/>
<path d="M1095.91 167.32C1084.86 167.32 1075 171.4 1068.37 178.71L1066.84 169.02H1047.29V253H1069.9V208.97C1069.9 196.05 1078.06 187.04 1090.3 187.04C1101.52 187.04 1108.49 194.52 1108.49 207.44V253H1131.1V206.08C1131.1 182.28 1117.33 167.32 1095.91 167.32Z" fill="white"/>
<path d="M1234.16 209.31C1234.16 199.79 1231.44 192.31 1224.98 184.83L1236.37 171.4L1220.05 159.16L1209.68 171.91C1203.73 168.68 1196.42 166.98 1188.6 166.98C1162.08 166.98 1143.21 184.66 1143.21 209.31C1143.21 234.98 1161.06 251.47 1189.11 251.47C1203.05 251.47 1211.21 257.08 1211.21 266.77C1211.21 276.29 1202.2 282.75 1188.77 282.75C1174.32 282.75 1165.65 275.44 1165.99 263.71H1144.06C1142.53 286.66 1160.38 302.3 1188.77 302.3C1216.31 302.3 1234.16 288.53 1234.16 267.28C1234.16 255.04 1227.19 245.69 1214.95 240.59C1227.36 234.13 1234.16 223.08 1234.16 209.31ZM1188.77 231.75C1175 231.75 1165.65 222.57 1165.65 209.31C1165.65 195.71 1174.83 186.7 1188.77 186.7C1202.71 186.7 1211.89 195.71 1211.89 209.31C1211.89 222.57 1202.71 231.75 1188.77 231.75Z" fill="white"/>
`]
