import { Field } from "@banklampung/components";
import { CCol, CFormGroup, CLabel } from "@coreui/react";
import PropTypes from "prop-types";
import React, { memo } from "react";

const FieldGroup = (props) => {
  const { layout = "vertical", ...py } = props;
  const {
    id,
    label,
    showLabel = true,
    isRequired,
    propsLabel,
    propsGroup,
  } = py;

  const { order: orderLabel } = propsLabel;

  const slotLabel = () => {
    return showLabel ? (
      <CLabel htmlFor={id} {...propsLabel}>
        {label} {isRequired && "*"}
      </CLabel>
    ) : null;
  };

  if (layout === "hide") {
    return <Field {...py} hidden />;
  } else if (layout === "horizontal") {
    return (
      <CFormGroup {...propsGroup} row>
        <CCol md="3">{slotLabel()}</CCol>
        <CCol md="9">
          <Field {...py} />
        </CCol>
      </CFormGroup>
    );
  } else {
    return (
      <CFormGroup {...propsGroup}>
        {orderLabel === "start" && slotLabel()}
        <Field {...py} />
        {orderLabel === "end" && slotLabel()}
      </CFormGroup>
    );
  }
};

FieldGroup.propTypes = {
  propsGroup: PropTypes.object,
  propsLabel: PropTypes.object,
};

FieldGroup.defaultProps = {
  propsGroup: {},
  propsLabel: {
    order: "start",
  },
};

export default memo(FieldGroup);
