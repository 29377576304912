import { LoadLazy } from "@banklampung/components";
import { usePostApi } from "@banklampung/hooks";
import * as Actions from "@banklampung/store/actions";
import {
  SweetDialog,
  addEventListeners,
  removeEventListeners,
} from "@banklampung/utils";
import { CContainer, CFade } from "@coreui/react";
import PropTypes from "prop-types";
import React, { lazy, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { sessionTimeoutInMilis } from "@banklampung/config";
import { AuthEndpoint } from "@app-endpoint";
import { HmacHelper } from "@banklampung/helper";

const SessionTimeoutModal = lazy(() => import("./SessionTimeoutModal"));

const Content = (props) => {
  const { routes, redirectPath } = props;
  const dispatch = useDispatch();
  const [isAfkModalOpen, setAfkModalOpen] = useState(false);

  const [, doSubmitLogout] = usePostApi({
    onSuccess: () => {
      const ref = window.location.pathname;

      dispatch(Actions.logoutUser(ref));
    },
  });

  const handleLogoutClick = () => {
    setAfkModalOpen(false);

    (async () => {
      const url = `/${AuthEndpoint.LOGOUT_ENDPOINT ?? "auth/oauth/revoke"}`;
      const signHeaders = await HmacHelper.getHeader({
        method: "POST",
        path: url
      });

      doSubmitLogout({
        url: url,
        headers: signHeaders,
      });
    })();
  };

  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        setAfkModalOpen(true);
      }, sessionTimeoutInMilis);

    const createTimeout2 = () =>
      setTimeout(() => {
        // Implement a sign out function here

        handleLogoutClick();
      }, 11000);

    const listener = () => {
      if (!isAfkModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };

    // Initialization
    let timeout = isAfkModalOpen ? createTimeout2() : createTimeout1();
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isAfkModalOpen]);

  return (
    <main className="c-main">
      <CContainer fluid>
        <ToastContainer position="top-right" autoClose={5000} />

        <SweetDialog />

        <LoadLazy>
          <SessionTimeoutModal
            show={isAfkModalOpen}
            onLogoutClick={handleLogoutClick}
            onStayClick={() => setAfkModalOpen(false)}
          />
        </LoadLazy>

        <LoadLazy>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component
                          pageTitle={route.name}
                          {...props}
                          {...route.props}
                        />
                      </CFade>
                    )}
                  />
                )
              );
            })}
            <Redirect from="/" to={redirectPath} />
          </Switch>
        </LoadLazy>
      </CContainer>
    </main>
  );
};

Content.propTypes = {
  routes: PropTypes.array,
  redirectPath: PropTypes.string,
};

Content.defaultProps = {
  routes: [],
  redirectPath: "/dashboard",
};

export default React.memo(Content);
