import { CForm } from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";

const Ticket = ({ content }) => {
  return (
    <div className="mx-auto align-items-center tax-payment-slip">
      <CForm className="form-horizontal">{content}</CForm>
    </div>
  );
};

Ticket.propTypes = {
  content: PropTypes.any.isRequired
};

export default Ticket;
