import { clientKey, zoneDateTime } from "@banklampung/config";
import { jwtService } from "@banklampung/services";
import { ZonedDateTime, ZoneId } from "@js-joda/core";
import "@js-joda/timezone";
import { notEmpty } from ".";
import { sha256 } from "js-sha256";

const toCamelString = (str) => {
  return str
    .split(" ")
    .map(function (word, index) {
      if (index == 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};

class HmacHelper {
  static getHeader = async ({ method, path, param = {}, body = {}, initJwt=true }) => {
    if(initJwt){
      jwtService.init();
    }

    let paramStr = "",
      bodyStr = "",
      accessToken = jwtService.getAccessToken(),
      signature = "";

    const timestamp = ZonedDateTime.now(ZoneId.of(zoneDateTime))
      .toString()
      .replace(`[${zoneDateTime}]`, "");

    if (notEmpty(param)) {
      const paramOrder = Object.keys(param)
        .sort()
        .reduce((obj, key) => {
          obj[key] = param[key];
          return obj;
        }, {});

      const buildParam = Object.keys(paramOrder)
        .map(function (k) {
          return k + "=" + paramOrder[k];
        })
        .join("&");

      // paramStr = `?${buildParam}`.replace(/\s+/g, "+");
      paramStr = `?${buildParam}`;
    }

    if (notEmpty(body)) {
      bodyStr = this.toPayload(body);

      // console.log("bodyStr: " + bodyStr);
    }

    // console.log("bodyStr: " + bodyStr);

    bodyStr = await this.toSha256(bodyStr);

    // console.log("bodySha256: " + bodyStr);

    // bodyStr = this.toHexa(bodyStr);

    // console.log("bodyHexa: " + bodyStr);

    signature = `${method}:${path}${paramStr}:${accessToken}:${bodyStr}:${timestamp}`;

    // console.log("signatureStr: " + signature);

    signature = await this.toHmac(signature);

    // console.log("signatureHmac: " + signature);

    // signature = this.toHexa(signature);

    // console.log("signatureHexa: " + signature);

    return {
      "X-BLPG-Key": clientKey,
      "X-BLPG-Timestamp": timestamp,
      "X-BLPG-Signature": signature,
    };
  };

  static toHmac = async (message) => {
    const keyBytes = this.getUtf8Bytes(clientKey);
    const messageBytes = this.getUtf8Bytes(message);

    // const cryptoKey = await crypto.subtle.importKey(
    //   "raw",
    //   keyBytes,
    //   { name: "HMAC", hash: "SHA-256" },
    //   true,
    //   ["sign"]
    // );
    // const signature = await crypto.subtle.sign("HMAC", cryptoKey, messageBytes);
    const signature = sha256.hmac.create(keyBytes);
    signature.update(messageBytes);

    return signature.hex();
    // return "";
  };

  static toSha256 = async (message) => {
    const messageBytes = this.getUtf8Bytes(message);

    // const signature = await crypto.subtle.digest("SHA-256", messageBytes);
    const signature = await sha256(messageBytes);

    return signature;
    // return "";
  };

  static toHexa = (sig) => {
    return [...new Uint8Array(sig)]
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  };

  static toPayload = (payload) => {
    let str = JSON.stringify(payload).replace(/\n|\r|\t/g, "");
    str = toCamelString(str);

    return str;
  };

  static getUtf8Bytes = (str) =>
    new Uint8Array(
      [...unescape(encodeURIComponent(str))].map((c) => c.charCodeAt(0))
    );
}

export default HmacHelper;
