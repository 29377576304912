import * as Actions from "../actions";

const fetchReducer = (state, action) => {
  switch (action.type) {
    case Actions.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case Actions.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false
      };
    case Actions.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      throw new Error();
  }
}

export default fetchReducer;
