class CustomerDataHelper {

    static customerColorStatus = (code) => {
        if ("DRAFT" === code) {
          return "warning";
        } else if ("ACTIVE" === code) {
          return "success";
        } else {
          return "secondary";
        }
    };

    static getGender = () => [
        {
            label: "Laki-Laki",
            value: "MALE",
        },
        {
            label: "Perempuan",
            value: "FEMALE",
        }
    ];

    static getBloodGroup = () => [
        {
            label: "A",
            value: "A",
        },
        {
            label: "B",
            value: "B",
        },
        {
            label: "AB",
            value: "AB",
        },
        {
            label: "O",
            value: "O",
        },
        {
            label: "Tidak Tahu",
            value: "NA",
        }
    ];

    static getReligion = () => [
        {
            label: "Islam",
            value: "ISLAM",
        },
        {
            label: "Kristen",
            value: "KRISTEN",
        },
        {
            label: "Katholik",
            value: "KATHOLIK",
        },
        {
            label: "Hindu",
            value: "HINDU",
        },
        {
            label: "Budha",
            value: "BUDHA",
        },
    ];

    static getMarital = () => [
        {
            label: "Menikah",
            value: "MARRIED",
        },
        {
            label: "Belum Menikah",
            value: "SINGLE",
        },
        {
            label: "Cerai Mati / Janda / Duda",
            value: "DEATHDIVORCE",
        },
        {
            label: "Cerai Hidup",
            value: "DIVORCED",
        },
    ];

    static getCustomerType = () => [
        {
            label: "Perorangan",
            value: "INDIVIDUAL"
        },
        {
            label: "Badan Usaha",
            value: "CORPORATE",
        },
        {
            label: "Pemerintahan",
            value: "GOVERNMENT",
        },
        {
            label: "Yayasan",
            value: "FOUNDATION",
        },
        {
            label: "Non-Badan Usaha",
            value: "NON_CORPORATE",
        },
    ]

    static getGovernmentType = () => [
        {
            label: "Pemerintah Daerah (PEMDA SIPD)",
            value: "GOVERNMENT_SIPD"
        },
        {
            label: "Pemerintah Desa (SISKEUDES)",
            value: "GOVERNMENT_SISKEUDES",
        },
        {
            label: 'Pemda / Dinas Lainnya',
            value: 'GOVERNMENT',
        },
    ]

    static getCorporateType = () => [
        {
            label: "Badan Usaha Milik Daerah (BUMD)",
            value: "BUMD",
        },
        {
            label: "Badan Usaha Milik Negara (BUMN)",
            value: "BUMN",
        },
        {
            label: "Perseroan Terbatas (PT)",
            value: "PT",
        },
        {
            label: "Persekutuan Komanditer (CV)",
            value: "CV",
        },
        {
            label: "Firma",
            value: "FIRMA",
        },
        {
            label: "Koperasi",
            value: "COOPERATIVE",
        },
    ]

    static getNonCorporateCustomerType = () => [
        {
            label: "Yayasan",
            value: "FOUNDATION",
        },
        {
            label: "Non-Badan Usaha Lainnya",
            value: "NON_CORPORATE",
        }
    ]

    static getNonCorporateType = () => [
        {
            label: "BUMDES / BUMTI",
            value: "BUMDES",
        },
        {
            label: "Sekolah",
            value: "SCHOOL",
        },
        {
            label: "Perguruan Tinggi",
            value: "COLLEGE",
        },
        {
            label: "Tempat Ibadah",
            value: "WORSHIP_PLACE",
        }
    ]

    static getWorshipPalace = () => [
        {
            label: "Masjid",
            value: "MASJID",
        },
        {
            label: "Gereja",
            value: "GEREJA",
        },
        {
            label: "Vihara / Wihara",
            value: "VIHARA",
        },
        {
            label: "Pura",
            value: "PURA",
        },
        {
            label: "Kelenteng",
            value: "KELENTENG",
        }
    ]

    static bankRelationship = () => [
        {
            label: "Tidak Terkait dengan Bank",
            value: "9900",
        },
        {
            label: "Pegawai Bank",
            value: "0210",
        },
        {
          label: "Pihak yang Memiliki Bank (Investor)",
          value: "0110",
        },
        {
          label: "Pihak yang Dimiliki Bank (Akuisisi)",
          value: "0120",
        },
        {
          label: "Pihak Afiliasi",
          value: "0130",
        },
        {
            label: "Pegawai Afiliasi",
            value: "0220",
        },
        {
            label: "Pihak Penjamin Bank (Asuransi)",
            value: "0130",
        }
    ]

    static getProfesion = () => [
        {
            label: "Belum/Tidak Bekerja",
            value: "UNEMPLOYMENT",
        },
        {
            label: "Petani",
            value: "FARMER",
        },
        {
            label: "Nelayan",
            value: "FISHERMAN",
        },
        {
            label: "Dokter",
            value: "DOCTOR",
        },
        {
            label: "Perawat",
            value: "NURSE",
        },
        {
            label: "Bidan",
            value: "MIDWIFE",
        },
        {
            label: "Apoteker",
            value: "PHARMACIST",
        },
        {
            label: "Pegawai Negri Sipil Kementrian (PNS-Kementrian)",
            value: "MINISTRY_PUBLIC_SERVICE",
        },
        {
            label: "Pegawai Negri Sipil Kementrian (PNS-Daerah)",
            value: "REGIONAL_PUBLIC_SERVICE",
        },
        {
            label: "Pensiunan",
            value: "RETIRED",
        },
        {
            label: "Anggota DPRD Kabupaten/Kota",
            value: "PARLIAMENT_MEMBERS",
        },
        {
            label: "Dosen Swasta",
            value: "STATE_LECTURER",
        },
        {
            label: "Dosen Negri",
            value: "PRIVATE_LECTURER",
        },
        {
            label: "Guru",
            value: "TEACHER",
        },
        {
            label: "Pegawai BANK",
            value: "BANKERS",
        },
        {
            label: "Pegawai BUMD",
            value: "BUMD_EMPLOYEE",
        },
        {
            label: "Pegawai BUMN",
            value: "BUMN_EMPLOYEE",
        },
        {
            label: "Pegawai Swasta",
            value: "PRIVATE_EMPLOYEE",
        },
        {
            label: "Tenaga Honorer",
            value: "HONORARY",
        },
        {
            label: "Polisi / Polri",
            value: "POLICE",
        },
        {
            label: "Arsitek",
            value: "ARCHITECT",
        },
        {
            label: "Kontraktor",
            value: "CONTRACTOR",
        },
        {
            label: "Konsultan",
            value: "CONSULTANT",
        },
        {
            label: "Penjahit",
            value: "TAILOR",
        },
        {
            label: "Ibu Rumah Tangga",
            value: "HOUSEWIFE",
        },
        {
            label: "Pedagang",
            value: "TRADER",
        },
        {
            label: "Peneliti",
            value: "RESEARCHERS",
        },
        {
            label: "Pengacara",
            value: "LAWYER",
        },
        {
            label: "Pilot",
            value: "PILOT",
        },
        {
            label: "Sopir / Ojek Online",
            value: "DRIVER",
        },
        {
            label: "Tentara",
            value: "ARMY",
        },
        {
            label: "Wartawan",
            value: "JOURNALIST",
        },
        {
            label: "Wiraswasta",
            value: "ENTREPRENEUR",
        },
        {
            label: "Pelajar / Mahasiswa",
            value: "STUDENT",
        },
        {
            label: "Lainnya",
            value: "OTHER",
        },        
    ];

    static getEducation = () => [
        {
            label: "Tidak / Belum Sekolah",
            value: "NO_SCHOOL",
        },
        {
            label: "Sekolah Dasar (SD)",
            value: "ELEMENTARY_SCHOOL",
        },
        {
            label: "Sekolah Menengah Pertama (SMP)",
            value: "JUNIOR_HIGH_SCHOOL",
        },
        {
            label: "Sekolah Menengah Atas Sederajat (SMA/MA/SMK)",
            value: "SENIOR_HIGH_SCHOOL",
        },
        {
            label: "Diploma I/II",
            value: "DI_II",
        },
        {
            label: "Diploma III / Sarjana Muda",
            value: "D3",
        },
        {
            label: "D4 / S1 - Sarjana",
            value: "S1",
        },
        {
            label: "S2 - Magister",
            value: "S2",
        },
        {
            label: "S3 - Doctor",
            value: "S3",
        },
        {
            label: "Lainnya",
            value: "OTHER",
        },
    ];

    static getNationality = () => [
        {
            label: "Warga Negara Indonesia",
            value: "WNI",
        },
        {
            label: "Warga Negara Asing",
            value: "WNA",
        },
    ];

    static getNumOfChildren = () => [
        {
            label: "Belum Memiliki Anak",
            value: 0,
        },
        {
            label: "1 Anak",
            value: 1,
        },
        {
            label: "2 Anak",
            value: 2,
        },
        {
            label: "3 Anak",
            value: 3,
        },
        {
            label: "4 Anak",
            value: 4,
        },
        {
            label: "4 Anak",
            value: 4,
        },
        {
            label: "5 Anak",
            value: 5,
        },
        {
            label: "6 Anak",
            value: 6,
        },
        {
            label: "7 Anak",
            value: 7,
        },
        {
            label: "8 Anak",
            value: 8,
        },
        {
            label: "9 Anak",
            value: 9,
        },
        {
            label: "10 Anak",
            value: 10,
        },
        {
            label: "Lebih dari 10 Anak",
            value: 99,
        },
    ]

    static getHomeOwnership = () => [
        {
            label: "Milik Sendiri",
            value: "OWN",
        },
        {
            label: "Milik Orang tua / Keluarga",
            value: "FAMILY",
        },
        {
            label: "Sewa / Kontrak",
            value: "RENT",
        },
        {
            label: "Dinas / Instansi",
            value: "OFFICE",
        },
        {
            label: "Lainnya",
            value: "OTHER",
        },
    ]

    static getRelationshipStatus = () => [
        {
            label: "Orang Tua",
            value: "PARENTS",
        },
        {
            label: "Kakak / Adik",
            value: "BROTHER_SISTER",
        },
        {
            label: "Suami / Istri",
            value: "COUPLE",
        },
        {
            label: "Anak",
            value: "CHILDREN",
        },
        {
            label: "Kakek / Nenek",
            value: "GRANDPARENTS",
        },
        
        {
            label: "Keponakan",
            value: "NEPHEW",
        },
        {
            label: "Paman / Bibi",
            value: "UNCLE_AUNT",
        },
        {
            label: "Family Lain",
            value: "OTHER_FAMILY",
        },    
        {
            label: "Wali / Lainnya",
            value: "OTHER",
        },    
    ]
}
export default CustomerDataHelper;