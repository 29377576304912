import React from "react";
import PropTypes from "prop-types";
import { CRow, CCol } from "@coreui/react";

const CenterButton = ({ content, size }) => {
  return (
    <CRow className="justify-content-around text-center">
      <CCol md={size || 5}>{content}</CCol>
    </CRow>
  );
};

CenterButton.propTypes = {
  content: PropTypes.any.isRequired
};

export default CenterButton;
