import _ from "@banklampung/libs/lodash";
import DateFormatter from "./DateFormatter";
import RegexHelper from "./RegexHelper";
import RupiahHelper from "./RupiahHelper";

class FieldFormatter {
  static toMaskMoney = (args) => {
    if (!(args === undefined || args === "" || args === NaN)) {
      let value = parseFloat(args);
      if (value % 1 != 0) {
        value = value.toFixed(2);
      }

      const eVal = value.toString().split(".");

      const absFormatted = RupiahHelper.toRupiah(eVal[0]);
      const absTerbilang = RupiahHelper.toTerbilang(eVal[0]);
      let commaFormatted = "",
        commaTerbilang = "";

      if (eVal[1]) {
        commaFormatted = `,${eVal[1]}`;
        commaTerbilang = ` Koma ${RupiahHelper.toTerbilang(eVal[1])}`;
      }
      return {
        value: value,
        formatted: `${absFormatted}${commaFormatted}`,
        speak: `${absTerbilang}${commaTerbilang} Rupiah`,
      };
    } else {
      return {};
    }
  };

  static toMaskNumber = (args) => {
    if (!(args === undefined || args === "" || args === NaN)) {
      let value = parseFloat(args);
      if (value % 1 != 0) {
        value = value.toFixed(2);
      }

      const eVal = value.toString().split(".");
      const absFormatted = RupiahHelper.toNumber(eVal[0]);

      return {
        value: value,
        formatted: `${absFormatted}`,
      };
    } else {
      return {};
    }
  };

  static toDate = (args) => {
    return args
      ? {
          value: RegexHelper.to("date", args),
          formatted: args,
        }
      : {};
  };

  static toDateI = (args) => {
    const { pattern, value } = args;

    return value
      ? {
          value: value,
          formatted: DateFormatter.to(pattern, value),
        }
      : {};
  };

  static toRegex = (args) => {
    const { type, value } = args;

    return value === null ||
      value === undefined ||
      value === "" ||
      value === isNaN
      ? {}
      : {
          value: value,
          formatted: RegexHelper.to(type, value),
        };
  };

  static toSelect = (args) => {
    const { opts, value } = args;

    return _.find(opts, { value: value }) || {};
  };

  static toAccountNumber = (accountNumber) => {
    const newData = accountNumber.toString()
      .split('')
      .map((x, index) => {
        if(index <= 12 ){
          if(index === 2){
              return x + ' ';
          }else if(index === 4){
              return x + ' ';
          }else if(index === 6){
              return x + ' ';
          }else if(index === 11){
              return x + ' ';
          }else if(index === 12){
              return x + ' ';
          }else{
              return x
          }
        }else{
          if((index % 4) === 0){
              return x + ' ';
          }else{
              return x
          }
        }
      })
    const joinStr = newData.join('');
    return joinStr;
  }

  static to = (type, args) => {
    switch (type) {
      case "mask-money": {
        return this.toMaskMoney(args);
      }

      case "mask-number": {
        return this.toMaskNumber(args);
      }

      case "date":
        return this.toDate(args);

      case "date-i": {
        return this.toDateI(args);
      }

      case "regex": {
        return this.toRegex(args);
      }

      case "select": {
        return this.toSelect(args);
      }

      default: {
        return "";
      }
    }
  };
}

export default FieldFormatter;
