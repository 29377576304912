import {
  Card,
  FooterSubmitForm,
  LoaderButton,
  LoadLazy,
} from "@banklampung/components";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const BasicFormClean = ({
  children,
  onSubmit,
  onReset,
  formName,
  formLabel,
  body,
  buttonSubmit,
  buttonReset,
  slotButton,
  slotButtonBefore,
  slotButtonAfter,
  buttonAlign,
  propsCard,
  outsideEnd,
  delayBtnActive,
  hideButton
}) => {
  const refBtnSubmit = useRef();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
      }, delayBtnActive);
    }
  }, [disabled]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisabled(true);

    if (!buttonSubmit.disabled) {
      refBtnSubmit.current.click();
    }
  };

  const handleReset = (event) => {
    event.preventDefault();
    if(onReset){
      onReset();
    }
  }

  return (
    <Card
      {...propsCard}
    >
      <h5 className="mb-2 mt-3">{ formLabel }</h5>

      <LoadLazy>
        <form onSubmit={onSubmit} name={formName} id={formName}>
          {body || children}

          <button
            type="submit"
            ref={refBtnSubmit}
            style={{
              display: "none",
            }}
          ></button>
        </form>
        {outsideEnd}
        <div className="required">* Wajib diisi</div>
      </LoadLazy>

      <FooterSubmitForm
        button={
          slotButton ? (
            <>
              { hideButton == false && (slotButton) }
            </>
          ) : (
            <>
              {slotButtonBefore}
              {hideButton == false && (
                <>
                  {buttonReset && (
                    <LoaderButton
                      {...buttonReset}
                      type="button"
                      disabled={disabled}
                      onClick={handleReset}
                      className={"mr-3"}
                    />
                  )}
                  <LoaderButton
                    {...buttonSubmit}
                    type="button"
                    disabled={disabled}
                    onClick={handleSubmit}
                    className={"ml-3"}
                  />
                </>
              )}
              {slotButtonAfter}
            </>
          )
        }
        align={buttonAlign}
      />
    </Card>
  );
};

BasicFormClean.propTypes = {
  onSubmit: PropTypes.any.isRequired,
  formName: PropTypes.string.isRequired,
  body: PropTypes.any,
  buttonSubmit: PropTypes.any.isRequired,
};

BasicFormClean.defaultProps = {
  delayBtnActive: 2000,
  propsCard: {},
  buttonSubmit: {
    disabled: false,
  },
  hideButton: false
};

export default BasicFormClean;
