const INQUIRY_ENDPOINT      = "fiona/inquiry";
const PRODUCT_ENDPOINT      = "fiona/products";
const PAYMENT_ENDPOINT      = "fiona/payment";
const LIST_ENDPOINT         = "fiona/list";


export default {
    INQUIRY_ENDPOINT,
    PAYMENT_ENDPOINT,
    PRODUCT_ENDPOINT,
    LIST_ENDPOINT
};