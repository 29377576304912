import * as Actions from "../actions";

const initialState = {
  state: "responsive",
  minimize: false,
};

const sidebar = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CHANGE_SIDEBAR: {
      return {
        ...state,
        state: action.payload,
      };
    }
    case Actions.CHANGE_MINIMIZE_SIDEBAR: {
      return {
        ...state,
        minimize: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default sidebar;
