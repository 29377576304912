import { LoadLazy } from "@banklampung/components";
import store from "@banklampung/store";
import { history } from "@banklampung/utils";
import "app/App.scss";
import { Auth } from "@app-auth";
import React, { lazy, useEffect } from "react";
import Provider from "react-redux/es/components/Provider";
import { Route, Router, Switch } from "react-router-dom";

const AppLayout = lazy(() => import("app/AppLayout"));
const AppLogin = lazy(() => import("@app-auth/login/AppLogin"));
const AppChangePassword = lazy(() =>
  import("@app-auth/password/AppChangePassword")
);

const App = (props) => {
  useEffect(() => {
    const elem = document.getElementById("loader");
    document.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    });
    window.onload = () => {
      if (elem) {
        elem.remove();
      }
    };
  }, []);

  return (
    <Provider store={store}>
      <Auth>
        <Router history={history}>
          <LoadLazy>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <AppLogin {...props} />}
              />
              <Route
                exact
                path="/change-password"
                name="Change Password Page"
                render={(props) => <AppChangePassword {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <AppLayout {...props} />}
              />
            </Switch>
          </LoadLazy>
        </Router>
      </Auth>
    </Provider>
  );
};

export default App;
