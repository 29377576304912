class RupiahHelper {
  static toRupiah = (amount) => {
    let rupiah = "";
    let amountRev = amount.toString().split("").reverse().join("");
    for (let i = 0; i < amountRev.length; i++)
      if (i % 3 == 0) rupiah += amountRev.substr(i, 3) + ".";
    return (
      "Rp" +
      rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("")
    );
  };

  static toNumber = (amount) => {
    let rupiah = "";
    let amountRev = amount.toString().split("").reverse().join("");
    for (let i = 0; i < amountRev.length; i++)
      if (i % 3 == 0) rupiah += amountRev.substr(i, 3) + ".";
    return (
      rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("")
    );
  };

  static toTerbilang(amount) {
    if (amount == 0) {
      return "Nol";
    }

    let v = 0,
      tanda = "",
      tmp = "",
      sub = "",
      subkoma = "",
      p1 = "",
      p2 = "";
    if (amount > 999999999999999999) {
      return "N/A";
    }
    v = amount;
    if (v < 0) {
      tanda = "Minus ";
    }
    v = Math.abs(v);
    tmp = v.toString().split(".");
    p1 = tmp[0];
    p2 = "";
    if (tmp.length > 1) {
      p2 = tmp[1];
    }
    v = parseFloat(p1);
    sub = this.prefixAmount(v);
    subkoma = this.endFixAmount(p2);
    sub = tanda + sub.replace("  ", " ");
    if (subkoma.replace(/ /g, "").length > 0) {
      sub = sub + "Koma " + subkoma.replace("  ", " ");
    }
    if (sub.replace(/ /g, "").length > 0) {
      sub = sub + " ";
    }
    const tempValue = sub.replace("  ", " ");
    return tempValue.trim();
  }

  static toRibuan = (amount) => {
    let rupiah = "";
    let amountRev = amount.toString().split("").reverse().join("");
    for (let i = 0; i < amountRev.length; i++)
      if (i % 3 == 0) rupiah += amountRev.substr(i, 3) + ".";
    return (
      rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("") + ",00"
    );
  };

  static prefixAmount = (uang) => {
    let sub = "";
    if (uang === 0) {
      sub = "Nol ";
    }
    if (uang === 1) {
      sub = "Satu ";
    } else if (uang === 2) {
      sub = "Dua ";
    } else if (uang === 3) {
      sub = "Tiga ";
    } else if (uang === 4) {
      sub = "Empat ";
    } else if (uang === 5) {
      sub = "Lima ";
    } else if (uang === 6) {
      sub = "Enam ";
    } else if (uang === 7) {
      sub = "Tujuh ";
    } else if (uang === 8) {
      sub = "Delapan ";
    } else if (uang === 9) {
      sub = "Sembilan ";
    } else if (uang === 0) {
      sub = "  ";
    } else if (uang === 10) {
      sub = "Sepuluh ";
    } else if (uang === 11) {
      sub = "Sebelas ";
    } else if (uang >= 11 && uang <= 19) {
      sub = this.prefixAmount(uang % 10) + "Belas ";
    } else if (uang >= 20 && uang <= 99) {
      sub =
        this.prefixAmount(Math.floor(uang / 10)) +
        "Puluh " +
        this.prefixAmount(uang % 10);
    } else if (uang >= 100 && uang <= 199) {
      sub = "Seratus " + this.prefixAmount(uang - 100);
    } else if (uang >= 200 && uang <= 999) {
      sub =
        this.prefixAmount(Math.floor(uang / 100)) +
        "Ratus " +
        this.prefixAmount(uang % 100);
    } else if (uang >= 1000 && uang <= 1999) {
      sub = "Seribu " + this.prefixAmount(uang - 1000);
    } else if (uang >= 2000 && uang <= 999999) {
      sub =
        this.prefixAmount(Math.floor(uang / 1000)) +
        "Ribu " +
        this.prefixAmount(uang % 1000);
    } else if (uang >= 1000000 && uang <= 999999999) {
      sub =
        this.prefixAmount(Math.floor(uang / 1000000)) +
        "Juta " +
        this.prefixAmount(uang % 1000000);
    } else if (uang >= 100000000 && uang <= 999999999999) {
      sub =
        this.prefixAmount(Math.floor(uang / 1000000000)) +
        "Milyar " +
        this.prefixAmount(uang % 1000000000);
    } else if (uang >= 1000000000000) {
      sub =
        this.prefixAmount(Math.floor(uang / 1000000000000)) +
        "Triliun " +
        this.prefixAmount(uang % 1000000000000);
    }
    return sub;
  };

  static endFixAmount = (t) => {
    if (t.length === 0) {
      return "";
    }
    return t
      .split("0")
      .join("Kosong ")
      .split("1")
      .join("Satu ")
      .split("2")
      .join("Dua ")
      .split("3")
      .join("Tiga ")
      .split("4")
      .join("Empat ")
      .split("5")
      .join("Lima ")
      .split("6")
      .join("Enam ")
      .split("7")
      .join("Tujuh ")
      .split("8")
      .join("Delapan ")
      .split("9")
      .join("Dembilan ");
  };
}

export default RupiahHelper;
