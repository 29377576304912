import React from "react";
import PropTypes from "prop-types";

const TextCenter = ({ value, color, style }) => {
  return (
    <div className={`text-center ${color ? `text-${color}` : ""} ${style}`}>
      {value}
    </div>
  );
};

TextCenter.propTypes = {
  value: PropTypes.any.isRequired,
};

export default TextCenter;
