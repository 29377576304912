import {
  Card,
  FieldGroup,
  IconButton,
  StaticFieldGroup,
} from "@banklampung/components";
import { CForm } from "@coreui/react";
import React, { memo } from "react";

const Item = (props) => {
  const { attr, pos, position, model, onFieldChange } = props;
  const { type, label, value, defVal, visibility, ...atr } = attr;
  const id = `${position}-${attr.id}`;

  if (visibility === false) return <></>;

  return type === "read" ? (
    <StaticFieldGroup
      label={label}
      value={typeof value === "object" ? value.formatted : value}
    />
  ) : (
    <FieldGroup
      {...atr}
      id={id}
      label={label}
      type={type}
      invalid={model.invalids[id]}
      errorMessage={model.errorMessages[id]}
      onChange={(event) => onFieldChange(event, model, position, pos)}
      defVal={typeof defVal === "object" ? defVal.value : defVal}
      value={typeof value === "object" ? value.value : value}
    />
  );
};

const FormItemRepeat = (props) => {
  const {
    propsGroup,
    propsItem,
    formName,
    formId,
    model,
    position,
    onDelete,
    onFieldChange,
    startIndex,
    deleteButtonPosition,
  } = props;
  const showDelete =
    model.showDelete === undefined ? true : model.showDelete === true;

  return (
    <Card
      key={`${formId}-${position}`}
      headerSlot={
        <>
          <strong>{`${formName} Ke-${position + startIndex}`}</strong>
          <div className="card-header-actions">
            {position > deleteButtonPosition && showDelete && (
              <IconButton
                color="danger"
                propsIcon={{ name: "cil-trash" }}
                label="Hapus"
                size="sm"
                onClick={(event) => onDelete(event, position)}
              />
            )}
          </div>
        </>
      }>
      <CForm
        id={`${formId}-${position}`}
        className={`d-flex flex-wrap ${propsGroup.className}`}>
        {model.attribute.map((attr, pos) => (
          <div key={pos} style={propsItem.style}>
            <Item
              pos={pos}
              position={position}
              propsItem={propsItem}
              attr={attr}
              model={model}
              onFieldChange={onFieldChange}
            />
          </div>
        ))}
      </CForm>
    </Card>
  );
};

FormItemRepeat.defaultProps = {
  formName: "Data Ke",
  propsGroup: {
    className: "",
  },
  propsItem: {},
  startIndex: 1,
  deleteButtonPosition: 0,
};

export default memo(FormItemRepeat);
