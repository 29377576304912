import {
  IconButton
} from "@banklampung/components";
import React, { useState } from "react";

const FieldChoice = (props) => {
  const { label, choices, onClick } = props;
  const [choice, setChoice] = useState();

  const onChoiceClick = (event, choice) => {
    event.preventDefault();

    setChoice(choice);

    if (onClick) {
      onClick(choice);
    }
  };

  return (
    <>
      <label className="mb-2 mt-3">{label}</label>

      <div className="d-flex justify-content-center mb-3">
        {choices.map(({ value, label }) => (
          <IconButton
            className="mr-3 btn-block align-self-center"
            propsIcon={{ name: choice === value ? "cil-check-alt" : "" }}
            color={choice === value ? "success" : "secondary"}
            label={label}
            onClick={(e) => onChoiceClick(e, value)}
          />
        ))}
      </div>
    </>
  );
};

FieldChoice.defaultProps = {};

export default FieldChoice;
