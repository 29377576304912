import React from "react";
import PropTypes from "prop-types";

const Sigma = ({ label }) => {
  return <span>&Sigma; {label}</span>;
};

Sigma.propTypes = {
  label: PropTypes.string.isRequired
};

export default Sigma;
