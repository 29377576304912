import _ from "@banklampung/libs/lodash";
import { useEffect, useRef } from "react";

const usePreviousCompare = value => {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useEffectObj = (callback, dependencies) => {
  useEffect(callback, usePreviousCompare(dependencies));
};
export default useEffectObj;
