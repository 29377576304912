export const CHANGE_SIDEBAR = "[SIDEBAR] CHANGE";
export const CHANGE_MINIMIZE_SIDEBAR = "[SIDEBAR] CHANGE MINIMIZE";

export const minimizeSidebar = () => ({
  type: CHANGE_MINIMIZE_SIDEBAR,
  payload: true,
});

export const maximizeSidebar = () => ({
  type: CHANGE_MINIMIZE_SIDEBAR,
  payload: false,
});
