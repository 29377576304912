import { hasAuthority } from "@banklampung/helper";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const Authorization = ({ permissions, rolesPermissions, isAuthorized, children }) => {
  const { roleAuthorities, permissionAuthorities } = useSelector(({ auth }) => ({
    roleAuthorities: auth.roles,
    permissionAuthorities: auth.authorities,
  }));

  const checkPermission = () => {
    if(rolesPermissions.length > 0 ){
      
      return hasAuthority(rolesPermissions, roleAuthorities);
    }else {
      
      return hasAuthority(permissions, permissionAuthorities);
    }
  }

  const isValid = useMemo(() =>
    isAuthorized ? isAuthorized :  checkPermission()
  );

  return isValid && children;  
};

Authorization.defaultProps = {
  isAuthorized: false,
  rolesPermissions: [],
  permissions:[]
};

export default Authorization;
