import { Authorization, ButtonLink, IconButton } from "@banklampung/components";
import * as GlobalActions from "@banklampung/store/actions";
import { history } from "@banklampung/utils";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

const AddAction = (props) => {
  const {
    resourceName,
    addLink,
    historyLink,
    toggleModal,
    resetAction,
    onClick
  } = props;
  const dispatch = useDispatch();
  const defPropsBtn = {
    color: "primary",
    size: "sm",
    className: "pull-right",
    propsIcon: { name: "cil-plus" },
    label: `Buat ${resourceName}`,
  };

  return (
    <div className="card-header-actions">
      {addLink && (
        <ButtonLink
          link={addLink}
          propsButton={{
            ...defPropsBtn,
            onClick: () => {
              if (onClick) {
                onClick();
              }

              if (resetAction) {
                dispatch({ type: resetAction });
              }
            },
          }}
        />
      )}
      {historyLink && (
        <IconButton
          onClick={() => {
            if (onClick) {
              onClick();
            }

            if (resetAction) {
              dispatch({ type: resetAction });
            }

            history.push({
              pathname: historyLink,
            });
          }}
          {...defPropsBtn}
        />
      )}
      {toggleModal && (
        <IconButton
          onClick={() => {
            if (onClick) {
              onClick();
            }

            if (resetAction) {
              dispatch({ type: resetAction });
            }

            dispatch(
              GlobalActions.openModal({
                title: `Form Buat ${resourceName}`,
              })
            );
          }}
          {...defPropsBtn}
        />
      )}
    </div>
  );
};
const HeaderList = (props) => {
  const { resourceName, createPermission } = props;

  return (
    <>
      <strong>Daftar {resourceName}</strong>
      {createPermission ? (
        <Authorization permissions={createPermission}>
          <AddAction {...props} />
        </Authorization>
      ) : (
        <AddAction {...props} />
      )}
    </>
  );
};

HeaderList.propTypes = {
  resourceName: PropTypes.string.isRequired,
};

HeaderList.defaultProps = {
  resourceName: "",
};

export default HeaderList;
