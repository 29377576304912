import React from "react";
import { FieldGroup, LoaderButton } from "@banklampung/components";
import { appDescription, appName, appVersion, envName, isDev, isCopyPasteForm } from "@banklampung/config";
import { useForm, usePostApi } from "@banklampung/hooks";
import { CBadge, CContainer, CForm, CRow } from "@coreui/react";
import { GlobalHelper } from "@banklampung/helper";
import { clientKey } from "@banklampung/config";
import { LocalStorage, history } from "@banklampung/utils";
import { AuthEndpoint } from "@app-endpoint";
import PropTypes from "prop-types";
import CIcon from "@coreui/icons-react";
import { customHandler } from "@banklampung/services/errorHandler";

const LoginNew = (props) => {
  const { companyName, departementName, logo, onSuccess, endpointAuth, children, formDeff } = props;

  const [{ isLoading: isLoadingLogin }, doSubmitLogin] = usePostApi({
    errorHandler: false,
    responseFormatted: "login",
    onSuccess: ({ payload }) => {
      onSuccessLogin({
        ...payload,
        form: form,
      })
    },
    onError: (error) => {
      customHandler({
        error: error,
        errorCode: "mfa_required",
        handleErorCodeExact: ({data}) => {
          const { mfaToken } = data;
          LocalStorage.setMFAToken(mfaToken);
          history.push(`/${AuthEndpoint.ROUTE_MFA_INDEX}`)
        }
      })
    },
  });

  const { form, handleChange, handleSubmit } = useForm({
    initialState: {
      corporateID: "",
      userID: "",
      password: "",
      ...formDeff
    },
    onSubmit: () => {
      onSubmitLogin();
    },
  });

  const onSubmitLogin = () => {
    (async () => {
      const url = endpointAuth;
      const password = `${form.password}:${form.corporateID}_${form.userID}`;
      const encrypt  = GlobalHelper.encryptTripleDES(password);
      
      const body = {
        grant_type: "password",
        corporate_unique_id: form.corporateID,
        user_id: form.userID,
        password: encrypt,
      };

      doSubmitLogin({
        url: url,
        body: body,
        headers: {
          "X-BLPG-Key": clientKey
        }
      });
    })();
  };

  const onSuccessLogin = (payload) => {  
    onSuccess(payload);
  };

  const isCopyPasteFormCheck = (e) => {
    if(isCopyPasteForm === "DISABLE"){
      e.preventDefault();
      return false;
    }
  }

  return (
      <CContainer>
        <CRow className="justify-content-center">
            <>
            
              {children}

              <CForm
                onSubmit={handleSubmit}
                name="formLogin"
                className="d-flex flex-column"
              >
                <CIcon
                  name={logo}
                  width="220"
                  height="80"
                  className="align-self-center"
                  alt="logo-bank-lampung"
                />
                <h2 className="mt-2 align-self-center">{appName}</h2>

                <h6 className="align-self-center">{appDescription}</h6>

                {isDev ? (
                  <CBadge color="dark" className="align-self-center">
                    {envName}
                  </CBadge>
                ) : (
                  <CBadge color="primary" className="align-self-center">
                    {envName}
                  </CBadge>
                )}

                <p className="text-muted mt-3 mb-4 align-self-center">
                  Sign In to start your session
                </p>

                <FieldGroup
                  id="corporateID"
                  label="ID Perusahaan"
                  placeholder="Masukan ID Perusahaan"
                  type="input"
                  isRequired={true}
                  validate={`["required", "len"]`}
                  param="[6,10]"
                  onChange={handleChange}
                  value={form.corporateID}
                  invalid={form.invalids.corporateID}
                  errorMessage={form.errorMessages.corporateID}
                  autoComplete={"off"}
                  onCut={isCopyPasteFormCheck}
                  onCopy={isCopyPasteFormCheck}
                  onPaste={isCopyPasteFormCheck}
                  onDrag={isCopyPasteFormCheck}
                  onDrop={isCopyPasteFormCheck}
                  onSelectStart={isCopyPasteFormCheck}
                />

                <FieldGroup
                  id="userID"
                  label="ID Pengguna"
                  placeholder="Masukan ID Pengguna"
                  type="input"
                  isRequired={true}
                  validate={`["required", "len"]`}
                  param="[5,50]"
                  onChange={handleChange}
                  value={form.userID}
                  invalid={form.invalids.userID}
                  errorMessage={form.errorMessages.userID}
                  autoComplete="off"
                  onCut={isCopyPasteFormCheck}
                  onCopy={isCopyPasteFormCheck}
                  onPaste={isCopyPasteFormCheck}
                  onDrag={isCopyPasteFormCheck}
                  onDrop={isCopyPasteFormCheck}
                  onSelectStart={isCopyPasteFormCheck}
                />

                <FieldGroup
                  id="password"
                  label="Password"
                  placeholder="Masukan password"
                  icon="cil-lock-locked"
                  type="input"
                  fieldType="input"
                  validate={`["required"]`}
                  className={"mask-password"}
                  invalid={form.invalids.password}
                  errorMessage={form.errorMessages.password}
                  onChange={handleChange}
                  defVal={form.password}
                  autoComplete="off"
                  onCut={isCopyPasteFormCheck}
                  onCopy={isCopyPasteFormCheck}
                  onPaste={isCopyPasteFormCheck}
                  onDrag={isCopyPasteFormCheck}
                  onDrop={isCopyPasteFormCheck}
                  onSelectStart={isCopyPasteFormCheck}
                />

                <LoaderButton
                  propsIcon={{ name: "cil-paper-plane" }}
                  label="Masuk"
                  isLoading={isLoadingLogin}
                  className="mt-1"
                />

                <div className="d-flex flex-column mt-5 align-self-center align-items-center">
                  <span>
                    {`${new Date().getFullYear()} @ ${departementName} - ${companyName}`}
                  </span>
                  <small className="text-muted">{`Versi ${appVersion}`}</small>
                </div>
              </CForm>
            </>
        </CRow>
      </CContainer>
  );
};

LoginNew.propTypes = {
  companyName: PropTypes.string,
  departementName: PropTypes.string,
  logo: PropTypes.string,
};

LoginNew.defaultProps = {
  companyName: "PT Bank Lampung",
  departementName: "Divisi TI",
};

export default LoginNew;
