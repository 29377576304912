import { AuthEndpoint } from "@app-endpoint";
import { useGetApi } from "@banklampung/hooks";
import { jwtService } from "@banklampung/services";
import * as GlobalActions from "@banklampung/store/actions";
import { history } from "@banklampung/utils";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Auth = ({ children }) => {
  const dispatch = useDispatch();

  const [, doFetchMe] = useGetApi({
    onSuccess: ({ payload }) => {
      dispatch(GlobalActions.setUserData(payload));

      if (payload.resetPassword) {
        history.push({
          pathname: "/change-password",
        });
      }
    },
  });

  useEffect(() => {
    jwtCheck();
  }, []);

  const jwtCheck = () => {
    jwtService.on("onAutoLogin", () => {
      doFetchMe({
        url: `${AuthEndpoint.USERS_ENDPOINT}/me`,
      });
    });

    jwtService.on("onAutoLogout", (message) => {
      if (message) {
        console.warn("Session invalid: ", message);

        const ref = window.location.pathname;

        dispatch(
          GlobalActions.openDialog({
            icon: "error",
            title: `Session Anda Habis`,
            text: "Silahkan login, untuk menggunakan aplikasi kembali",
            showConfirmButton: true,
            onConfirm: () => {
              dispatch(GlobalActions.logoutUser(ref));
            },
          })
        );
      } else {
        dispatch(GlobalActions.logoutUser());
      }
    });

    jwtService.init();
  };

  return <>{children}</>;
};

export default Auth;
