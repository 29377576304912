import React from "react";
import { CCol, CRow } from "@coreui/react";
import { LoadLazy } from "@banklampung/components";

const ColTwo = ({ key, propsRow, propsColA, propsColB, childA, childB }) => {
  return (
    <CRow key={key} {...propsRow}>
      <CCol {...propsColA}>
        <LoadLazy>{childA}</LoadLazy>
      </CCol>
      <CCol {...propsColB}>
        <LoadLazy>{childB}</LoadLazy>
      </CCol>
    </CRow>
  );
};

export default ColTwo;
