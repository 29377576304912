import useApi from "./useApi";

const usePutApi = (props) => {
  const result = useApi({
    method: "put",
    progressDialog: {
      show: true,
    },
    ...props,
  });
  return result;
};

export default usePutApi;
