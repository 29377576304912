import { LoadLazy } from "@banklampung/components";
import { hasAuthority } from "@banklampung/helper";
import _ from "@banklampung/libs/lodash";
import * as GlobalActions from "@banklampung/store/actions";
import CIcon from "@coreui/icons-react";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarMinimizer,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavTitle,
} from "@coreui/react";
import CSidebarNavItem from "./CustomSudebarNavItem"
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const { brandFull, brandMini, navigation, light } = props;
  const { permissionAuthorities, roleAuthorities, sidebarShow, sidebarMinimize } = useSelector(
    ({ auth, sidebar }) => ({
      permissionAuthorities: auth.authorities,
      roleAuthorities: auth.roles,
      sidebarShow: sidebar.state,
      sidebarMinimize: sidebar.minimize,
    })
  );

  const checkPermission = (nav) => {
    const permissions = nav.permissions;
    const rolesPermissions = nav.rolesPermissions;

    if(rolesPermissions){
      return hasAuthority(rolesPermissions, roleAuthorities);
    }else {
      
      return hasAuthority(permissions, permissionAuthorities);
    }
  }

  const style = () => {
    if(light == true){
      return {
        background: "#3d4b64"
      }
    }else{
      return {}
    }
  }

  return (
    <CSidebar
      show={sidebarShow}
      minimize={sidebarMinimize}
      onShowChange={(val) =>
        dispatch({ type: GlobalActions.CHANGE_SIDEBAR, payload: val })
      }
      onMinimizeChange={(val) =>
        dispatch({ type: GlobalActions.CHANGE_MINIMIZE_SIDEBAR, payload: !val })
      }
      className={(light && "c-sidebar-light")}
    >
      <CSidebarBrand className="d-md-down-none" to="/" style={style()}>
        <CIcon
          className="c-sidebar-brand-full"
          name={brandFull}
          height={47}
          alt="logo-bank-lampung"
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name={brandMini}
          height={35}
          alt="logo-bank-lampung"
        />
      </CSidebarBrand>
      <LoadLazy>
        <CSidebarNav>
          <CCreateElement
            items={_.filter(navigation, (nav) => {
              return checkPermission(nav);
            })}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
              // CustomSidebarNavItem,
            }}
          />
        </CSidebarNav>
      </LoadLazy>

      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

Sidebar.propTypes = {
  brandFull: PropTypes.string,
  brandMini: PropTypes.string,
  navigation: PropTypes.array,
};

Sidebar.defaultProps = {
  navigation: [],
};

export default React.memo(Sidebar);
