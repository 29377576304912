const INQUIRY_ENDPOINT = "arenga/inquiry";
const PAYMENT_ENDPOINT = "arenga/payments";
const CHECK_STATUS_ENDPOINT = "arenga/check-status";
const FLAG_ENDPOINT = "arenga/pay-flag";

export default {
  INQUIRY_ENDPOINT,
  PAYMENT_ENDPOINT,
  CHECK_STATUS_ENDPOINT,
  FLAG_ENDPOINT
};
