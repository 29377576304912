import * as GlobalActions from "@banklampung/store/actions";
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";


const BasicModal = ({ children, slotFooter, pageTitle }) => {
  const show = useSelector(({ modal }) => modal.state);
  const { title, backdrop, size } = useSelector(({ modal }) => modal.options);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(GlobalActions.closeModal());
  };

  return (
    <div>
      <CModal
        show={show}
        onClose={onClose}
        backdrop={backdrop}
        size={size}
      >
        <CModalHeader closeButton>
          <CModalTitle>{ pageTitle ?? title}</CModalTitle>
        </CModalHeader>

        <CModalBody>{children}</CModalBody>

        {slotFooter && <CModalFooter>{slotFooter}</CModalFooter>}
      </CModal>
    </div>
  );
};

BasicModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BasicModal;
