import useApi from "./useApi";

const usePatchApi = (props) => {
  const result = useApi({
    method: "patch",
    progressDialog: {
      show: true,
    },
    ...props,
  });
  return result;
};

export default usePatchApi;
